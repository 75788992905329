import { AfterContentInit, Component, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { map, Subject, Subscription, takeUntil, timer } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import * as MainActions from '../../store/main/main.actions';
import * as HomeActions from '../../store/home/home.actions';
import { LogbookAppState } from '../../store/logbook.reducer';
import { LayoutConstants } from '../layout-constants';
import { environment } from '../../../environments/environment';
import {
  collapseOpenDropdown,
  collapseOtherNavBarDropdowns,
  goLink,
  onResize,
  setBackgroundPattern,
  setHeaderAttributes,
  setMenuAttributes,
  toggleProfileDropdown,
} from '../../shared/helper/navbar-helper';
import { LayoutAnimations } from '../animations';
import { getCurrentDateTimeWithoutTimeZoneString } from '../../shared/helper/date';
import { PageHeaderService } from '../../shared/service/page-header/page-header.service';
import { IPageHeader } from '../../shared/service/page-header/page-header.model';
import { User } from '../../store/user/model';
import { MonitoringService } from '../../shared/service/error-service/monitoring.service';
import { NotificationList } from '../../shared/service/notification/notification.service';
import { MomentDateFormat } from '../../shared/helper/date.constants';
import * as moment from 'moment';
import { MENU_INFORMATION } from '../../../constants';
import { PinChangeComponent } from '../../shared/component/pin-change/pin-change.component';
import { ELogbookGridView } from '../../view/home/logbook-grid-view/logbook-grid-view.model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ERights, IMainState } from '../../store/main/main.model';
import { IClientSettingsState } from '../../store/settings/client/client.model';
import { ForgotPinComponent } from '../../shared/component/forgot-pin/forgot-pin.component';
import * as ForgotPinActions from '../../store/forgot-pin/forgot-pin.actions';

const { version: appVersion } = require('../../../../package.json');

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: [
    './main.component.scss',
    '../../../assets/icon/icofont/css/icofont.scss',
    '../../../scss/navigation-bar.scss',
  ],
  animations: [
    LayoutAnimations.notificationBottom,
    LayoutAnimations.mobileHeaderNavRight,
    LayoutAnimations.fadeInOutTranslate,
    LayoutAnimations.mobileMenuTop,
  ],
})
export class MainComponent extends LayoutConstants implements OnInit, OnDestroy, AfterContentInit {
  @ViewChild(PinChangeComponent) pinChangeComponent!: PinChangeComponent;
  @ViewChild(ForgotPinComponent) forgotPinComponent!: ForgotPinComponent;
  onResize = onResize;
  setHeaderAttributes = setHeaderAttributes;
  setMenuAttributes = setMenuAttributes;
  toggleProfileDropdown = toggleProfileDropdown;
  collapseOtherNavBarDropdowns = collapseOtherNavBarDropdowns;
  collapseOpenDropdown = collapseOpenDropdown;
  setBackgroundPattern = setBackgroundPattern;
  scroll = scroll;
  goLink = goLink;

  public appVersion;
  public homeAuth: boolean = false;
  public pageHeader: IPageHeader = {
    title: '',
    titleKey: '',
    icon: '',
    fullScreenButton: false,
    fullScreenTargetElementId: '',
  };

  readonly deviceType = {
    desktop: 'desktop',
    tablet: 'tablet',
    phone: 'phone',
  };

  private mainStoreSubscription!: Subscription;
  private userStoreSubscription!: Subscription;
  private clientStoreSubscription!: Subscription;
  private pageHeaderSubscription!: Subscription;

  public clock!: string;
  public timeFormat$: string = MomentDateFormat.TIME_24H;
  private timeZone$!: string;
  private timer!: Subscription;
  private momentFormat: 'MMM D, YYYY h:mm A' | 'MMM D, YYYY H:mm' = MomentDateFormat.DATETIME_24H;
  private readonly scwModalSm: string = 'scw-modal-sm';
  public unSeenNotifications$: number = 0;
  private notificationList$: NotificationList = { duration: '', next: '', results: [], unread: 0, unseen: 0 };

  public userId!: number;
  public userFullName!: string | null;
  public logoUrl!: string;
  public logoSafeUrl!: SafeUrl | null;
  public navBarDropdowns: any;
  private isTemporaryPing!: boolean | undefined;
  public accessToOee: boolean | undefined;
  public oeeUrl: string | undefined;
  public readonly submitATicketUrl: string = environment.submitATicketUrl;
  public hasSubmitTicketRight: boolean = false;

  constructor(
    private readonly sanitizer: DomSanitizer,
    private store: Store<LogbookAppState>,
    public router: Router,
    private translate: TranslateService,
    private toast: ToastrService,
    private pageHeaderService: PageHeaderService,
    private monitoringService: MonitoringService,
    private http: HttpClient,
    @Inject('API_BASE_URL') public readonly baseUrl: string,
    private zone: NgZone,
  ) {
    super();
    this.setHeaderAttributes(this.windowWidth);
    this.setMenuAttributes(this.windowWidth);
    this.setHeaderAttributes(this.windowWidth);
    this.appVersion = appVersion;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.router.events.subscribe((routerEvent) => {
      if (routerEvent instanceof NavigationEnd) {
        // trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });
  }

  public reloadCurrentPage() {
    window.location.reload();
  }

  public ngOnInit(): void {
    this.setBackgroundPattern('theme1');

    this.mainStoreSubscription = this.store.select('mainStore').subscribe((state: IMainState) => {
      this.hasSubmitTicketRight = state.userRights[ERights.SUBMIT_TICKET];
      this.logoSafeUrl = state.clientLogoUrl;
    });

    this.userStoreSubscription = this.store.select('user').subscribe((state: User) => {
      this.notificationList$ = state.notifications;
      this.unSeenNotifications$ = this.notificationList$.unseen;
      this.userName = String(state.username);
      this.clientLogo = state.clientLogoId;
      this.userId = Number(state.userId);
      this.userFullName = `${state.firstName} ${state.lastName}`;
      this.homeAuth = true;
      this.timeZone$ = String(state.timezone);
      this.isTemporaryPing = state.isPinTemporary;
      const selectedFormat = moment().format(String(state.dateTimeFormat));

      if (selectedFormat.search('AM') !== -1 || selectedFormat.search('PM') !== -1) {
        this.momentFormat = MomentDateFormat.DATETIME_12H;
        this.timeFormat$ = MomentDateFormat.TIME_12H;
      }

      this.setClientLogo();
    });

    this.clientStoreSubscription = this.store.select('clientStore').subscribe((state: IClientSettingsState) => {
      this.accessToOee = state.clientCommonInformation?.accessToOee;
      this.oeeUrl = state.clientCommonInformation?.oeeUrl;
    });

    this.store.dispatch(new MainActions.GetNavigationMenuCompleted(MENU_INFORMATION, true));

    if (this.pcodedDeviceType !== this.deviceType.desktop) {
      this.pageHeaderPaddingTop = '0';
    }

    this.pageHeaderSubscription = this.pageHeaderService.pageHeader.subscribe((item: IPageHeader) => {
      this.pageHeader = {
        title: item.title,
        titleKey: item.titleKey,
        icon: item.icon,
        fullScreenButton: item.fullScreenButton,
        fullScreenTargetElementId: item.fullScreenTargetElementId,
        isTabPage: item.isTabPage,
        showPageConfiguration: item.showPageConfiguration,
        showCountDownButton: item.showCountDownButton,
        showFilterBarVisibilityButton: item.showFilterBarVisibilityButton,
        showPrintFunctionalityButton: item.showPrintFunctionalityButton,
        hidePageHeader: item.hidePageHeader,
        noOverlap: item.noOverlap,
        moveUncheckedToEnd: item.moveUncheckedToEnd,
      };
    });
  }

  public userLogout(): void {
    this.monitoringService.logoutAuthorizedUser();
    this.http
      .get<any>(`${environment.ssoUrl}${environment.ssoLogoutUrl}`)
      .pipe()
      .subscribe((_resData: null) => {
        window.location.href = environment.ssoProviderLogoutUrl + location.origin;
      });
  }

  private setClientLogo(): void {
    this.logoUrl = this.clientLogo
      ? `${this.baseUrl}/files/image/clients/${this.clientLogo}`
      : 'assets/images/scw-logo.png';

    if (this.clientLogo && !this.logoSafeUrl) {
      this.http
        .get(this.logoUrl, { responseType: 'blob', headers: { 'app-version': environment.version } })
        .pipe(map((val: Blob) => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))))
        .subscribe((url: SafeUrl) => {
          this.logoSafeUrl = url;
          this.store.dispatch(new MainActions.SetClientLogoUrl(this.logoSafeUrl));
        });
    }
  }

  public ngOnDestroy(): void {
    if (this.userStoreSubscription) {
      this.userStoreSubscription.unsubscribe();
    }

    if (this.pageHeaderSubscription) {
      this.pageHeaderSubscription.unsubscribe();
    }

    if (this.mainStoreSubscription) {
      this.mainStoreSubscription.unsubscribe();
    }

    this.timer.unsubscribe();
  }

  public resetHomeTab(): void {
    this.store.dispatch(new HomeActions.GridViewChanged(ELogbookGridView.Network));
  }

  public ngAfterContentInit(): void {
    this.zone.runOutsideAngular(() => {
      const oneSecondTimer = timer(0, 1000);
      this.timer = oneSecondTimer.subscribe(() => {
        const time = getCurrentDateTimeWithoutTimeZoneString();
        if (this.clock === undefined || !moment(time).isSame(this.clock, 'minute')) {
          this.zone.run(() => {
            this.clock = time;
          });
        }
      });
    });
  }

  public showPinChangeModal(): void {
    this.pinChangeComponent.showModal(this.isTemporaryPing ? 'temporary' : 'edit');
  }

  public showForgotPinModal(): void {
    this.store.dispatch(new ForgotPinActions.GetAllowedDomains());
    this.forgotPinComponent.showModal();
  }

  public onClickGoToOee(oeeUrl: string | undefined) {
    if (!oeeUrl) {
      return;
    }

    const destroySubject: Subject<boolean> = new Subject<boolean>();
    const tokenRenewalUrl: string = `${environment.ssoUrl}${environment.ssoSilentAuthUrl}`;

    this.http
      .get<any>(tokenRenewalUrl)
      .pipe(takeUntil(destroySubject))
      .subscribe(() => {
        destroySubject.next(true);

        window.location.href = oeeUrl;
      });
  }
}
