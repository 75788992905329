import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, UntypedFormControl } from '@angular/forms';
import { replaceDateStringForValidator } from '../../helper/date';

@Directive({
  selector: '[customMinDate][formControlName],[customMinDate][formControl],[customMinDate][ngModel]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: CustomMinDateDirective,
    multi: true,
  }],
})

export class CustomMinDateDirective implements Validator {
  @Input() customMinDate: any;

  validate(input: UntypedFormControl): { customMinDate: boolean; [key: string]: any } | null {
    let customValue = input.value;

    if (
      customValue === null || customValue === undefined ||
      customValue === '' || this.customMinDate === null ||
      this.customMinDate === undefined || this.customMinDate === ''
    ) {
      return null;
    }

    customValue = new Date(replaceDateStringForValidator(customValue));

    if (typeof this.customMinDate === 'string') {
      this.customMinDate = new Date(replaceDateStringForValidator(this.customMinDate));
    } else {
      this.customMinDate = new Date(this.customMinDate);
    }

    return (customValue.getTime() < this.customMinDate.getTime()) ? { customMinDate : true } : null;
  }
}
