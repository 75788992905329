<button class="advanced_filter" [class.disabled]="isDisabled" (click)="showAdvancedFilterModal(advancedFilterModal)">
  <div class="advanced_filter-text">{{ 'filterCard.advancedFilter.title' | translate }}</div>
  <div class="advanced_filter-decorations">
    <div class="advanced_filter-decorations-count" *ngIf="appliedFilterCount > 0">+{{ appliedFilterCount }}</div>
    <em class="fas fa-ellipsis-h advanced_filter-decorations-icon"></em>
  </div>
</button>

<!-- Modal -->
<ng-template #advancedFilterModal let-modal>
  <scw-mat-form (scwFormSubmit)="onSubmit($event)">
    <div class="modal-header scw-modal-header">
      <h3 class="modal-title scw-header">{{ 'filterCard.advancedFilter.title' | translate }}</h3>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="isChangesApplied ? modal.close() : showConfirmationModal(confirmationModal)"
      >
        <span aria-hidden="true">
          <em class="fas fa-times"></em>
        </span>
      </button>
    </div>
    <div class="modal-body">
      <div class="advanced_filter-body">
        <div class="advanced_filter-controls">
          <div class="advanced_filter-controls-left">
            <scw-mat-small-button
              (onClick)="selectAll()"
              *ngIf="selectAllType === 'select'"
              [disabled]="filters.length === 0"
            >
              {{ 'general.selectAll' | translate }}
            </scw-mat-small-button>
            <scw-mat-small-button (onClick)="deSelectAll()" *ngIf="selectAllType === 'de-select'">
              {{ 'general.unselectAll' | translate }}
            </scw-mat-small-button>
            <scw-mat-small-button
              class="trash-icon"
              (onClick)="deleteFilters()"
              [iconButton]="true"
              [disabled]="isDeleteButtonDisabled"
            >
              <em class="fas fa-trash"></em>
            </scw-mat-small-button>
          </div>
          <div class="advanced_filter-controls-right">
            <scw-mat-small-button (onClick)="addFilter()" [disabled]="isAddButtonDisabled">
              <em class="fas fa-plus"></em>
              {{ 'filterCard.advancedFilter.addFilter' | translate }}
            </scw-mat-small-button>
          </div>
        </div>

        <div class="advanced_filter-item" *ngFor="let filter of filters; index as key">
          <div class="advanced_filter-item-row">
            <scw-mat-checkbox
              class="advanced_filter-checkbox"
              [(inputModel)]="filter.isActive"
              (inputModelChange)="onFormChange()"
            ></scw-mat-checkbox>
            <scw-mat-select
              [data]="fields"
              [labelKey]="'field'"
              [noPadding]="true"
              [(inputModel)]="filter.selectedField"
              (inputModelChange)="onFieldChange(filters.indexOf(filter))"
              [clearAll]="false"
            ></scw-mat-select>
            <scw-mat-select
              [data]="filter.operators"
              [labelKey]="'translatedOperator'"
              [noDataLabel]="'filterCard.advancedFilter.noFieldSelectedForOperator' | translate"
              [noPadding]="true"
              [disabled]="
                filter.selectedField.length > 0 &&
                filter.selectedField[0].queryType === QueryTypes.withinRequestQueryString
              "
              [(inputModel)]="filter.selectedOperator"
              (inputModelChange)="onOperatorChange(filter)"
              [clearAll]="false"
            ></scw-mat-select>
            <scw-mat-input
              *ngIf="filter.selectedFieldType === InputTypes.text"
              [block]="true"
              [noPadding]="true"
              [rules]="inputRules.text"
              [disabled]="
                filter.selectedField.length === 0 ||
                filter.selectedOperator.length === 0 ||
                filter.selectedOperator[0].type === OperatorTypes.boolean
              "
              [(inputModel)]="filter.value"
              (inputModelChange)="onFormChange()"
            ></scw-mat-input>
            <scw-mat-input
              *ngIf="filter.selectedFieldType === InputTypes.number"
              [block]="true"
              [rules]="inputRules.number"
              [noPadding]="true"
              [disabled]="
                filter.selectedField.length === 0 ||
                filter.selectedOperator.length === 0 ||
                filter.selectedOperator[0].type === OperatorTypes.boolean
              "
              [(inputModel)]="filter.value"
              (inputModelChange)="onFormChange()"
            ></scw-mat-input>
            <scw-mat-input
              *ngIf="filter.selectedFieldType === InputTypes.decimal"
              [block]="true"
              [rules]="inputRules.decimal"
              [noPadding]="true"
              (isValidChange)="inputInvalid($event)"
              [disabled]="
                filter.selectedField.length === 0 ||
                filter.selectedOperator.length === 0 ||
                filter.selectedOperator[0].type === OperatorTypes.boolean
              "
              [(inputModel)]="filter.value"
              (inputModelChange)="onFormChange()"
            ></scw-mat-input>
            <scw-mat-input
              *ngIf="filter.selectedFieldType === InputTypes.duration"
              [block]="true"
              [rules]="inputRules.duration"
              [noPadding]="true"
              (isValidChange)="inputInvalid($event)"
              [disabled]="
                filter.selectedField.length === 0 ||
                filter.selectedOperator.length === 0 ||
                filter.selectedOperator[0].type === OperatorTypes.boolean
              "
              [(inputModel)]="filter.value"
              (inputModelChange)="onFormChange()"
            ></scw-mat-input>
            <scw-mat-select
              *ngIf="filter.selectedFieldType === InputTypes.select"
              [data]="
                filter.selectedField.length > 0 && $any(filter.selectedField[0]).predefinedValues
                  ? $any(filter.selectedField[0]).predefinedValues
                  : []
              "
              [disabled]="
                filter.selectedField.length === 0 ||
                filter.selectedOperator.length === 0 ||
                filter.selectedOperator[0].type === OperatorTypes.boolean
              "
              [labelKey]="'name'"
              [noPadding]="true"
              [singleSelection]="
                filter.selectedField.length > 0 && filter.selectedField[0]['singleSelection'] !== undefined
                  ? filter.selectedField[0]['singleSelection']
                  : true
              "
              [showCheckbox]="
                filter.selectedField.length > 0 && filter.selectedField[0]['showCheckbox'] !== undefined
                  ? filter.selectedField[0]['showCheckbox']
                  : false
              "
              [enableSearchFilter]="filter.selectedField[0]?.showSearchBox?.showSearchBox"
              [searchBehavior]="filter.selectedField[0]?.showSearchBox?.searchBehaviour"
              [(inputModel)]="filter.value"
              (inputModelChange)="onFormChange()"
              [clearAll]="false"
            ></scw-mat-select>
            <scw-mat-select
              *ngIf="filter.selectedFieldType === InputTypes.dateFormat"
              [data]="
                filter.selectedField.length > 0 && $any(filter.selectedField[0]).predefinedValues
                  ? $any(filter.selectedField[0]).predefinedValues
                  : []
              "
              [disabled]="
                filter.selectedField.length === 0 ||
                filter.selectedOperator.length === 0 ||
                filter.selectedOperator[0].type === OperatorTypes.boolean
              "
              [labelKey]="'name'"
              [noPadding]="true"
              [(inputModel)]="filter.value"
              (inputModelChange)="onFormChange()"
              [clearAll]="false"
            ></scw-mat-select>
            <scw-mat-datepicker
              *ngIf="
                !(
                  helperService.isTouchDevice &&
                  filter.selectedOperator.length > 0 &&
                  filter.selectedOperator[0].type === OperatorTypes.range
                ) &&
                (filter.selectedFieldType === InputTypes.date || filter.selectedFieldType === InputTypes.dateTime)
              "
              [singleDatePicker]="
                filter.selectedOperator.length > 0 && filter.selectedOperator[0].sql !== SqlOperators.range
              "
              [opens]="DateRangeOpens.left"
              [drops]="key >= 5 ? 'up' : 'down'"
              [autoApply]="true"
              [timePicker]="filter.selectedFieldType === InputTypes.dateTime"
              [showCustomRangeLabel]="false"
              [ranges]="null"
              [wide]="filter.selectedOperator.length > 0 && filter.selectedOperator[0].type === OperatorTypes.range"
              [disabled]="
                filter.selectedField.length === 0 ||
                filter.selectedOperator.length === 0 ||
                filter.selectedOperator[0].type === OperatorTypes.boolean
              "
              [emitOnNull]="true"
              [emitUtc]="true"
              [noPadding]="true"
              [class]="dateFormat && dateFormat.length > 10 ? 'datepicker-long-format' : 'datepicker-short-format'"
              [(inputModel)]="filter.value"
              (inputModelChange)="onFormChange()"
            ></scw-mat-datepicker>
            <!-- mock div to fill flex space for datepicker -->
            <div
              *ngIf="
                helperService.isTouchDevice &&
                filter.selectedOperator.length > 0 &&
                filter.selectedOperator[0].type === OperatorTypes.range
              "
            ></div>
            <scw-mat-checkbox
              *ngIf="filter.selectedFieldType === InputTypes.checkbox"
              class="advanced_filter-item-boolean_checkbox"
              [disabled]="
                filter.selectedField.length === 0 ||
                filter.selectedOperator.length === 0 ||
                filter.selectedOperator[0].type === OperatorTypes.boolean
              "
              [(inputModel)]="filter.value"
              (inputModelChange)="onFormChange()"
            ></scw-mat-checkbox>
          </div>
          <ng-template
            [ngIf]="
              helperService.isTouchDevice &&
              filter.selectedOperator.length > 0 &&
              filter.selectedOperator[0].type === OperatorTypes.range
            "
          >
            <div class="advanced_filter-item-row">
              <scw-mat-datepicker
                *ngIf="filter.selectedFieldType === InputTypes.date || filter.selectedFieldType === InputTypes.dateTime"
                [autoApply]="true"
                [timePicker]="filter.selectedFieldType === InputTypes.dateTime"
                [closeOnAutoApply]="true"
                [showCustomRangeLabel]="false"
                [ranges]="null"
                [singleDatePicker]="false"
                [rangePickerStartLabel]="'general.start' | translate"
                [rangePickerEndLabel]="'general.end' | translate"
                [noPadding]="true"
                [wide]="filter.selectedOperator.length > 0 && filter.selectedOperator[0].type === OperatorTypes.range"
                [disabled]="
                  filter.selectedField.length === 0 ||
                  filter.selectedOperator.length === 0 ||
                  filter.selectedOperator[0].type === OperatorTypes.range
                "
                [emitOnNull]="true"
                [containerClass]="'datepicker-adv-filter-iPad'"
                [(inputModel)]="filter.value"
                (inputModelChange)="onFormChange()"
              ></scw-mat-datepicker>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="buttons-right">
        <scw-mat-button
          [isSubmitButton]="true"
          type="standard"
          (onClick)="clickSetAsDefaultButton()"
          [disabled]="isSetDefaultButtonDisabled"
          [hidden]="isSetDefaultButtonHide"
        >
          {{ 'general.setAsDefaultButton' | translate }}
        </scw-mat-button>
        <scw-mat-button [isSubmitButton]="true" [disabled]="isApplyButtonDisabled" (onClick)="clickApplyButton()">
          {{ 'general.apply' | translate }}
        </scw-mat-button>
      </div>
    </div>
  </scw-mat-form>
</ng-template>

<ng-template #confirmationModal let-modal>
  <scw-mat-form (scwFormSubmit)="onSubmit($event)">
    <div class="modal-header scw-modal-header">
      <h3 class="modal-title scw-header">
        {{ 'filterCard.advancedFilter.title' | translate }}
      </h3>
      <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.close()">
        <span aria-hidden="true">
          <em class="fas fa-times"></em>
        </span>
      </button>
    </div>
    <div class="modal-body">
      <div class="advanced_filter-confirmation_body">
        <em class="fas fa-exclamation-circle"></em>
        <div class="advanced_filter-confirmation_body-text">
          {{ 'filterCard.advancedFilter.confirmation' | translate }}
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="buttons-left">
        <scw-mat-button type="danger" (onClick)="onDontSave()">
          {{ 'general.dontApply' | translate }}
        </scw-mat-button>
      </div>
      <div class="buttons-right">
        <scw-mat-button type="standard" (onClick)="modal.close()">
          {{ 'general.cancel' | translate }}
        </scw-mat-button>
        <scw-mat-button [isSubmitButton]="true" [disabled]="isApplyButtonDisabled" (onClick)="clickApplyButton()">
          {{ 'general.apply' | translate }}
        </scw-mat-button>
      </div>
    </div>
  </scw-mat-form>
</ng-template>
