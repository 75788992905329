import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { forkJoin, map, Observable } from 'rxjs';
import {
  IBaseOneResponse,
  IBulkResponseRecord,
  IGetManyResponse,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { IIssuer } from '../../../../shared/component/issuer/issuer.model';
import { IIssuerAndReason } from '../../../../shared/component/before-action-preparer/before-action-preparer.model';
import { IAddRule, IBulkEditRule, IRelevantData, IRule } from './rule.model';
import { StatesService } from '../../states/states.service';
import { ActivityTypeService } from '../../activity-type/activity-type.service';
import { ServiceUtilities } from '../../../../shared/helper/service-utilities';
import { IState } from '../../states/states.model';
import { IActivityType } from '../../activity-type/activity-type.model';
import * as _ from 'lodash';
import { IRuleType } from '../../../../shared/component/filter/filter.class';

@Injectable({
  providedIn: 'root',
})
export class RuleService {
  private readonly routes = {
    RULES: '/rules/',
    RULE_TYPES: '/rule-types/',
    BULK: 'bulk/',
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly stateService: StatesService,
    private readonly activityTypeService: ActivityTypeService,
  ) {}

  public getRules(params: Record<string, string | number>): Observable<IGetManyResponse<IRule>> {
    return this.http.post<IGetManyResponse<IRule>>(
      `${this.baseUrl}${this.routes.RULES}`,
      { ...params },
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public addRule(rule: IAddRule, issuer: IIssuer | null): Observable<IBaseOneResponse<IRule>> {
    return this.http.post<IBaseOneResponse<IRule>>(`${this.baseUrl}${this.routes.RULES}`, {
      issuer,
      payload: rule,
    });
  }

  public editRule(rule: IAddRule, id: number, issuer: IIssuer | null): Observable<IBaseOneResponse<IRule>> {
    return this.http.put<IBaseOneResponse<IRule>>(`${this.baseUrl}${this.routes.RULES}${id}/`, {
      issuer,
      payload: rule,
    });
  }

  public deleteRule(
    ruleIds: number[],
    issuerAndReason: IIssuerAndReason,
  ): Observable<IGetManyResponse<IBulkResponseRecord<IRule>>> {
    return this.http.delete<IGetManyResponse<IBulkResponseRecord<IRule>>>(
      `${this.baseUrl}${this.routes.RULES}${this.routes.BULK}`,
      {
        body: {
          issuer: issuerAndReason.issuer,
          payload: {
            reason: issuerAndReason.reason,
            data: ruleIds,
          },
        },
      },
    );
  }

  public bulkEditRule(
    rules: IBulkEditRule[],
    issuerAndReason: IIssuerAndReason,
  ): Observable<IGetManyResponse<IBulkResponseRecord<IRule>>> {
    return this.http.put<IGetManyResponse<IBulkResponseRecord<IRule>>>(
      `${this.baseUrl}${this.routes.RULES}${this.routes.BULK}`,
      {
        issuer: issuerAndReason.issuer,
        reason: issuerAndReason.reason,
        payload: rules,
      },
    );
  }

  public getRuleTypes(): Observable<IGetManyResponse<IRuleType>> {
    return this.http.get<IGetManyResponse<IRuleType>>(`${this.baseUrl}${this.routes.RULE_TYPES}`);
  }

  public getRelevantData(): Observable<IRelevantData> {
    const httpParams: HttpParams = ServiceUtilities.prepareGenericHttpParamsForRequest({
      page: 1,
      rowsPerPage: 5000,
      isActive: true,
    });
    const body: Record<string, string | number> = ServiceUtilities.prepareGenericBodyForRequest({
      page: 1,
      rowsPerPage: 5000,
      isActive: true,
      compact: true,
    });

    const observables: (Observable<IGetManyResponse<IState>> | Observable<IGetManyResponse<IActivityType>>)[] = [
      this.stateService.getStates(body),
      this.activityTypeService.getActivityTypes(httpParams),
    ];

    return forkJoin(observables).pipe(
      map((responseList: (IGetManyResponse<IState> | IGetManyResponse<IActivityType>)[]) => {
        return {
          states: _.get(responseList, '0.data', []),
          activityTypes: _.get(responseList, '1.data', []),
        };
      }),
    );
  }
}
