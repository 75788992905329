import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appToggleNestedTable]',
})
export class ToggleNestedTableDirective {
  constructor(private readonly elements: ElementRef) {}

  private static toggleClass(elementId: number, className: string): void {
    document.getElementById(String(elementId))?.children[0]?.classList?.toggle(className);
  }

  @HostListener('click')
  onClick(): void {
    const targetElementId: number | null = this.elements.nativeElement.id;

    if (targetElementId) {
      document
        .getElementById(String(targetElementId))
        ?.parentElement?.parentElement?.nextElementSibling?.firstElementChild?.classList?.toggle('d-none');

      ToggleNestedTableDirective.toggleClass(targetElementId, 'fa-caret-down');
      ToggleNestedTableDirective.toggleClass(targetElementId, 'fa-caret-right');
    }
  }
}
