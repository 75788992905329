import { Component, Input, OnInit } from '@angular/core';
import { ActionsSubject, Store } from '@ngrx/store';
import { LogbookAppState } from '../../../store/logbook.reducer';
import {
  GET_USER_FEED_TOKEN_LOADED,
  GetNotificationList,
  GetUserFeedToken,
  GetUserFeedTokenLoaded,
} from '../../../store/user/actions';
import { IGetFeedToken, NotificationList } from '../../../shared/service/notification/notification.service';
import { StreamClient, StreamFeed, connect } from 'getstream';
import { ofType } from '@ngrx/effects';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  @Input() isOpened: boolean = false;
  private feedTokens$!: IGetFeedToken;
  private client!: StreamClient;
  private feed!: StreamFeed;
  private isFeedTokenLoaded$: boolean = false;
  public isFeedTokenLoading$: boolean = false;
  public notificationsLoading$: boolean = false;
  public notificationsLoaded$: boolean = false;
  public notificationList$: NotificationList = { results: [], next: null, duration: null, unseen: 0, unread: 0 };
  public hasNextNotification: boolean = false;

  constructor(private readonly store: Store<LogbookAppState>, private readonly userActions: ActionsSubject) {
    this.store.dispatch(new GetUserFeedToken());
  }

  ngOnInit() {
    this.userActions.pipe(ofType(GET_USER_FEED_TOKEN_LOADED)).subscribe((action: GetUserFeedTokenLoaded) => {
      this.feedTokens$ = action.userFeed;
    });
    this.store.select('user').subscribe((state) => {
      this.isFeedTokenLoaded$ = state.isFeedTokenLoaded;
      this.isFeedTokenLoading$ = state.isFeedTokenLoading;
      this.notificationsLoading$ = state.notificationsLoading;
      this.notificationsLoaded$ = state.notificationsLoaded;
      this.notificationList$ = state.notifications;
      this.hasNextNotification = this.notificationList$.next !== '' && this.notificationList$.next !== null;
    });
  }

  private createUserFeed(): void {
    this.client = connect(this.feedTokens$.apiKey, null, this.feedTokens$.appId);
    this.feed = this.client.feed(this.feedTokens$.feedname, this.feedTokens$.feeduser, this.feedTokens$.feedtoken);
    this.feed
      .subscribe(() => {
        this.getNotificationList(!this.isOpened);
      })
      .then((r) => {
        return r;
      });
  }

  public getNotificationList(justCount: boolean = true): void {
    this.store.dispatch(new GetNotificationList(justCount));
  }

  public loadMoreNotifications(): void {
    if (this.hasNextNotification) {
      this.store.dispatch(new GetNotificationList(false, this.notificationList$.id_lt));
    }
  }
}
