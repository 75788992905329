import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DefaultEditor, ViewCell } from 'ng2-smart-table';
import { HelperService } from '../../service/helper.service';
import * as moment from 'moment';

import { OWL_DATE_TIME_PROVIDERS } from '../../../../constants';
import { Store } from '@ngrx/store';
import { LogbookAppState } from '../../../store/logbook.reducer';
import { take, Subscription, timer } from 'rxjs';
import { User } from '../../../store/user/model';
import { PickerType } from 'ng-pick-datetime-ex/lib/date-time/date-time.class';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: OWL_DATE_TIME_PROVIDERS,
})
export class DatePickerComponent extends DefaultEditor implements OnInit {
  @Input() type!: string;
  @Input() selectedTime!: string;
  @Input() isDisabled: boolean = false;
  @Output() timeToEmit = new EventEmitter<string>();

  public now = new Date();
  public nowXHRInterval!: Subscription;
  pickerType!: PickerType;
  dateValidation!: Date;
  public dateFormat$!: string;

  public formattedDate!: string;

  constructor(public readonly helperService: HelperService, private readonly store: Store<LogbookAppState>) {
    super();
  }

  public ngOnInit() {
    this.store
      .select('user')
      .pipe(take<User>(1))
      .subscribe((user: User) => {
        this.dateFormat$ = user.dateFormat ?? '';
      });

    if (this.type === 'date') {
      this.pickerType = 'calendar';
    }
    this.selectedTime = this.formatDate(moment());
    this.formattedDate = moment(this.selectedTime).format(this.dateFormat$);

    const aMinuteInMS = 60000;
    this.nowXHRInterval = timer(aMinuteInMS)
      .pipe(take(1))
      .subscribe(() => {
        this.now = new Date();
      });
    this.nowXHRInterval?.unsubscribe();
  }

  public onChange(selectedDate: string | null = null, device: string | null = null): void {
    if (!selectedDate) {
      this.formattedDate = '';
      return;
    }

    const momentSelectedDate: moment.Moment = moment(selectedDate);

    if (momentSelectedDate.isValid()) {
      this.formattedDate = momentSelectedDate.format(this.dateFormat$);
    }

    if (device === 'picker') {
      this.selectedTime = this.validateFormat(selectedDate);
      this.timeToEmit.emit(this.selectedTime);
    } else {
      this.selectedTime = this.formatDate(selectedDate);
    }
  }

  validateFormat(selectedTime: any): string {
    this.dateValidation = new Date(selectedTime);
    if (this.dateValidation > this.now) {
      return this.formatDate(this.now);
    }
    return this.formatDate(selectedTime);
  }

  formatDate(date: any): string {
    if (this.helperService.isTouchDevice) {
      return moment(date).format('YYYY-MM-DD');
    }
    return this.helperService.setUserDateTimeFormat(date, false);
  }

  ngOnDestroy(): void {
    this.nowXHRInterval?.unsubscribe();
  }
}

@Component({
  template: "{{value | momentDate:'MMM D, YYYY'}}",
})
export class DatePickerRendererComponent implements ViewCell {
  @Input() value!: string;
  @Input() rowData: any;
}
