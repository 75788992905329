import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, UntypedFormControl } from '@angular/forms';

@Directive({
  selector: '[customMax][formControlName],[customMax][formControl],[customMax][ngModel]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: CustomMaxDirective,
    multi: true,
  }],
})

export class CustomMaxDirective implements Validator {
  @Input() customMax!: number | null;

  validate(input: UntypedFormControl): { customMax: boolean; [key: string]: any } | null {
    if (input.value === null || input.value === undefined || input.value === '') {
      return null;
    }
    let customValue = input.value;
    if (customValue instanceof String) {
      customValue = input.value.replace(',', '.');
    }
    customValue = +(customValue);
    return (customValue > Number(this.customMax)) ? { customMax : true } : null;
  }
}
