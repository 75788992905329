import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'timezone',
})
export class TimezonePipe implements PipeTransform {
  transform(value: string, timezone: string): moment.Moment {
    return moment(value).tz(timezone);
  }
}
