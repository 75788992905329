import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { IDatatableWithDraggableRowHeader } from '../../../../constants.model';
import { CdkDragSortEvent, moveItemInArray } from '@angular/cdk/drag-drop';
import { ComponentUtilities } from '../../helper/component-utilities';
import { IGenericObject } from '../../model/interface/generic.model';

@Component({
  selector: 'scw-datatable-with-draggable-rows',
  templateUrl: './datatable-with-draggable-rows.component.html',
  styleUrls: ['./datatable-with-draggable-rows.component.scss'],
})
export class DatatableWithDraggableRowsComponent<T> implements OnChanges {
  @Input() headers: IDatatableWithDraggableRowHeader[] = [];
  @Input() items!: T[];
  @Input() noDataText!: string;
  @Input() changeValue!: string;
  @Input() hideDragHandle: boolean = false;
  @Input() dragPreviewTemplateRef?: TemplateRef<any>;
  @Input() errorText: string | null = null;
  @Input() hint: string | null = null;
  @Input() draggableColumnText: string | null = null;
  @Input() overflowVisible: boolean = false;
  @Input() responsiveTable: boolean = false;
  @Input() dragButtonEnabled: boolean = false;

  @Output() itemsChange = new EventEmitter();

  @ContentChild(TemplateRef, { static: false }) templateRef!: TemplateRef<any>;

  public currentDragIndex: number | null = null;
  public readonly genericTrackByItemFunction = ComponentUtilities.genericTrackByItemFunction;
  public readonly datatableHeaderTrackBy = ComponentUtilities.datatableHeaderTrackBy;

  drop(event: any): void {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    this.setChangeValue();
  }

  dragSorted($event: CdkDragSortEvent): void {
    this.currentDragIndex = $event.currentIndex;
  }

  public setChangeValue(): void {
    if (this.changeValue) {
      this.items.forEach((item: IGenericObject<any>, index: number) => {
        item[this.changeValue] = index + 1;
      });
    }

    this.itemsChange.emit(this.items);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('items' in changes) {
      this.setChangeValue();
    }
  }
}
