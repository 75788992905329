import {
  FilterDataServiceCRUDTypes,
  IFormsCRUD,
  ILogbooksCRUD,
  IUsersCRUD,
  IUsersFilterData,
} from '../../component/filter/filter.class';

export interface IFilterService {
  getData(): Promise<FilterDataServiceCRUDTypes[]>;
}

export interface IPagination extends IResponse {
  count: number;
  page: number;
  total: number;
  pageCount: number;
}

export interface IResponse {
  success: boolean;
  date: string;
  message?: string;
}

export interface IUserFilterDataResponse extends IPagination {
  data: IUsersFilterData[];
}

export interface IUserCRUDResponse extends IPagination {
  data: IUsersCRUD[];
}

export interface IFormsCRUDResponse extends IPagination {
  data: IFormsCRUD[];
}

export interface ILogbooksCRUDResponse extends IPagination {
  data: ILogbooksCRUD[];
}
