import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  IBaseOneResponse,
  IBulkResponseRecord,
  IGetManyResponse,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { IIssuer } from '../../../../shared/component/issuer/issuer.model';
import { IIssuerAndReason } from '../../../../shared/component/before-action-preparer/before-action-preparer.model';
import { IAddAutomation, IBulkEditAutomation, IAutomation } from './automation.model';

@Injectable({
  providedIn: 'root',
})
export class AutomationService {
  private readonly routes = {
    AUTOMATIONS: '/automations/',
    BULK: 'bulk/',
  };

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getAutomations(params: HttpParams): Observable<IGetManyResponse<IAutomation>> {
    return new Observable<IGetManyResponse<IAutomation>>((obs) => {
      obs.next({
        data: [],
        success: true,
      });
    });

    // return this.http.get<IGetManyResponse<IAutomation>>(`${this.baseUrl}${this.routes.AUTOMATIONS}`, { params });
  }

  public addAutomation(automation: IAddAutomation, issuer: IIssuer | null): Observable<IBaseOneResponse<IAutomation>> {
    return this.http.post<IBaseOneResponse<IAutomation>>(`${this.baseUrl}${this.routes.AUTOMATIONS}`, {
      issuer,
      payload: automation,
    });
  }

  public editAutomation(automation: IAddAutomation, id: number, issuer: IIssuer | null): Observable<IBaseOneResponse<IAutomation>> {
    return this.http.put<IBaseOneResponse<IAutomation>>(`${this.baseUrl}${this.routes.AUTOMATIONS}${id}/`, {
      issuer,
      payload: automation,
    });
  }

  public deleteAutomation(id: number, issuerAndReason: IIssuerAndReason): Observable<IBaseOneResponse<IAutomation>> {
    return this.http.delete<IBaseOneResponse<IAutomation>>(`${this.baseUrl}${this.routes.AUTOMATIONS}${id}/`, {
      body: {
        issuer: issuerAndReason.issuer,
        payload: {
          reason: issuerAndReason.reason,
        },
      },
    });
  }

  public bulkDeleteAutomation(
    automationIds: number[],
    issuerAndReason: IIssuerAndReason,
  ): Observable<IGetManyResponse<IBulkResponseRecord<IAutomation>>> {
    return this.http.delete<IGetManyResponse<IBulkResponseRecord<IAutomation>>>(
      `${this.baseUrl}${this.routes.AUTOMATIONS}${this.routes.BULK}`,
      {
        body: {
          issuer: issuerAndReason.issuer,
          payload: {
            reason: issuerAndReason.reason,
            data: automationIds,
          },
        },
      },
    );
  }

  public bulkEditAutomation(
    automations: IBulkEditAutomation[],
    issuerAndReason: IIssuerAndReason,
  ): Observable<IGetManyResponse<IBulkResponseRecord<IAutomation>>> {
    return this.http.put<IGetManyResponse<IBulkResponseRecord<IAutomation>>>(
      `${this.baseUrl}${this.routes.AUTOMATIONS}${this.routes.BULK}`,
      {
        issuer: issuerAndReason.issuer,
        reason: issuerAndReason.reason,
        payload: automations,
      },
    );
  }
}
