<div
  id="styleSelector"
  class="settings-selector"
  data-html2canvas-ignore
  [class.open]="sideBarStatus"
>
  <div
    class="selector-toggle"
    [ngClass]="{ 'customFilterButtonToggle': customFilterButton !== undefined }"
  >
    <a
      href="javascript:"
      placement="left"
      class="ripple light"
      ngbTooltip="{{
        options !== undefined ? options.caretTooltipText : ('general.accountSettings' | translate)
      }}"
      (click)="toggleSideBar()"
    >
      <ng-container *ngIf="customFilterButton !== undefined && !sideBarStatus">
        <ng-template [ngTemplateOutlet]="customFilterButton"></ng-template>
      </ng-container>
    </a>
  </div>
  <ng-content *ngTemplateOutlet="customHeader"></ng-content>
  <perfect-scrollbar [style.max-width]="'100%'">
    <div class="style-cont m-t-10">
      <ngb-tabset>
        <ng-template [ngIf]="customTabHeader !== undefined && customTabContent !== undefined">
          <ngb-tab>
            <ng-template ngbTabTitle>
              <ng-content *ngTemplateOutlet="customTabHeader"></ng-content>
            </ng-template>
            <ng-template ngbTabContent>
              <div [@fadeInOutTranslate] class="m-t-15">
                <ng-content *ngTemplateOutlet="customTabContent"></ng-content>
              </div>
            </ng-template>
          </ngb-tab>
        </ng-template>
        <ng-template ngFor [ngForOf]="tabs" let-tab let-tabIndex="index">
          <ngb-tab>
            <ng-template ngbTabTitle>
              <div class="tab-header-title">
                {{ tab.texts?.tabTitle }}
              </div>
            </ng-template>
            <ng-template ngbTabContent>
              <div [@fadeInOutTranslate] class="m-t-15">
                <div
                  cdkDropList
                  class="datatable-list"
                  (cdkDropListDropped)="drop($event, tab.rows)"
                  cdkDropListOrientation="vertical"
                >
                  <div
                    class="datatable-box"
                    *ngFor="let item of tab.rows; index as rowIndex"
                    cdkDrag
                    [cdkDragDisabled]="!item.selected"
                  >
                    <ng-template [ngIf]="options.showDragBar">
                      <ng-container *ngIf="!isMobile; else mobileDrag">
                        <em class="fa fa-bars fa-2x"></em>
                      </ng-container>
                      <ng-template #mobileDrag>
                        <em class="fa fa-bars fa-2x" cdkDragHandle></em>
                      </ng-template>
                    </ng-template>
                    {{ item.title }}
                    <ui-switch
                      class="js-small pl-10"
                      color="#007bff"
                      switchColor="#fff"
                      size="small"
                      [checked]="item.selected"
                      [disabled]="item.disabled || false"
                      (change)="onItemSwitchChange($event, tabIndex, rowIndex)"
                    ></ui-switch>
                  </div>
                </div>
              </div>
              <div class="row table-setting-buttons">
                <div class="col-md-12">
                  <button
                    class="btn"
                    [ngClass]="tab.styles?.applyButtonClass || 'btn-primary'"
                    (click)="onApplyButtonClick(tabIndex, tab.rows, tab.key)"
                  >
                    {{ tab.texts?.applyButton }}
                  </button>
                  <button
                    class="btn pull-right"
                    [ngClass]="tab?.styles?.setAsDefaultButtonClass || 'btn-primary'"
                    (click)="onSetAsDefaultButtonClick(tabIndex, tab.rows, tab.key)"
                  >
                    {{ tab.texts?.setAsDefault }}
                  </button>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
        </ng-template>
      </ngb-tabset>
    </div>
  </perfect-scrollbar>
</div>
