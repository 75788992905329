<datatable
  paginationSize="lg"
  noDataText="{{ 'datatable.noData' | translate }}"
  [serverSide]="true"
  [shouldPaginate]="false"
  [headers]="headers"
  [items]="items"
  [itemsCount]="itemsCount"
  [tableClass]="tableClass"
>
  <ng-template let-item>
    <td *ngFor="let header of headers">
      <ng-container *ngIf="header.value === 'actionBy'; else createdAt">
        <app-e-signature [signatureDetails]="item.actionBy" [createdAt]="item.createdAt"></app-e-signature>
      </ng-container>
      <ng-template #createdAt>
        <ng-container *ngIf="['actionAt', 'createdAt'].includes(header.value); else history">
          <span class="white-space-pre">
            {{ item[header.value] | momentDate: undefined:{ withoutYear: false, withLineBreak: true } }}
          </span>
        </ng-container>
      </ng-template>
      <ng-template #history>
        <ng-container *ngIf="header.value === 'change'; else reason">
          <ng-template [ngIf]="item.updateCanceled">
            {{ 'auditTrail.noUpdateMade' | translate }}
          </ng-template>
          <div class="change-cell" *ngIf="!item.updateCanceled">
            <div *ngFor="let data of item.change; index as i">
              <div *ngIf="data['old'] != data['new']" class="row mb-3">
                <div class="hiddenForDocument"><br></div>
                <strong *ngIf="data['fieldType'] !== 'parent_logbook'" class="col-12 mb-2 change-column-title">{{
                  data['fieldType'] === 'source' ? ('general.user' | translate) : data['label']
                }}</strong>
                <strong
                  *ngIf="data['fieldType'] === 'parent_logbook' && i === 0"
                  class="col-12 mb-2 logbook-moved-label"
                  >{{ 'general.logbookMove' | translate }}</strong
                >
                <div class="max-content">
                  <div class="change-column-content">
                    <span class="content-field">
                      <ng-container *ngIf="data['fieldType'] === 'datetime'; else checkboxFieldsOld">
                        "{{ data['old'] | momentDate: undefined:{ withoutYear: false, withLineBreak: true } }}"
                      </ng-container>
                      <ng-template #checkboxFieldsOld>
                        <ng-container *ngIf="data['fieldType'] === 'checkbox'; else urlFieldOld">
                          "{{ yesNo(data['old']) }}"
                        </ng-container>
                      </ng-template>
                      <ng-template #urlFieldOld>
                        <ng-container *ngIf="data['fieldType'] === 'url'; else textAreaFieldOld">
                          "<a target="_blank" href="{{ getUrl(data['old']) }}">{{ data['old'] }}</a
                          >"
                        </ng-container>
                      </ng-template>
                      <ng-template #textAreaFieldOld>
                        <ng-container *ngIf="data['fieldType'] === 'textarea'; else timeFieldOld">
                          "{{ data['old'] }}"
                        </ng-container>
                      </ng-template>
                      <ng-template #timeFieldOld>
                        <ng-container *ngIf="data['fieldType'] === 'time'; else fileFieldOld">
                          "{{ data['old'] | historyTableTimePipe }}"
                        </ng-container>
                      </ng-template>
                      <ng-template #fileFieldOld>
                        <ng-container *ngIf="data['fieldType'] === 'file'; else otherChangeFieldsOld">
                          "{{ getFile(data['old']) }}"
                        </ng-container>
                      </ng-template>
                      <ng-template #otherChangeFieldsOld>
                        <ng-container *ngIf="!fieldTypesWithoutOldValues.includes(data['fieldType'])"
                          >"{{ data['old'] }}"</ng-container
                        >
                      </ng-template>
                    </span>
                  </div>

                  <ng-template [ngIf]="!fieldTypesWithoutOldValues.includes(data['fieldType'])">
                    <span class="fas fa-arrow-right float-left"></span>
                    <div class="hiddenForDocument">-></div>
                  </ng-template>

                  <div
                    [ngClass]="data['fieldType'] === 'source' ? 'change-column-content-full-width' : 'change-column-content'"
                  >
                    <span
                      class="content-field"
                      [class.content-field-parent-logbook]="data['fieldType'] === 'parent_logbook'"
                      [class.white-space-pre-line]="data['fieldType'] === 'source'"
                    >
                      <ng-container *ngIf="data['fieldType'] === 'datetime'; else checkboxFieldsNew">
                        "{{ data['new'] | momentDate: undefined:{ withoutYear: false, withLineBreak: true } }}"
                      </ng-container>
                      <ng-template #checkboxFieldsNew>
                        <ng-container *ngIf="data['fieldType'] === 'checkbox'; else urlFieldNew">
                          "{{ yesNo(data['new']) }}"
                        </ng-container>
                      </ng-template>
                      <ng-template #urlFieldNew>
                        <ng-container *ngIf="data['fieldType'] === 'url'; else textAreaFieldNew">
                          "<a target="_blank" href="{{ getUrl(data['new']) }}">{{ data['new'] }}</a
                          >"
                        </ng-container>
                      </ng-template>
                      <ng-template #textAreaFieldNew>
                        <ng-container *ngIf="data['fieldType'] === 'textarea'; else timeFieldNew">
                          "{{ data['new'] }}"
                        </ng-container>
                      </ng-template>
                      <ng-template #timeFieldNew>
                        <ng-container *ngIf="data['fieldType'] === 'time'; else sourceFieldNew">
                          "{{ data['new'] | historyTableTimePipe }}"
                        </ng-container>
                      </ng-template>
                      <ng-template #sourceFieldNew>
                        <ng-container *ngIf="data['fieldType'] === 'source'; else fileFieldNew">
                          {{ data['new']['sourceObject']['firstName'] }} {{ data['new']['sourceObject']['lastName'] }}
                        </ng-container>
                      </ng-template>
                      <ng-template #fileFieldNew>
                        <ng-container *ngIf="data['fieldType'] === 'file'; else parentLogbookFieldNew">
                          "{{ getFile(data['new']) }}"
                        </ng-container>
                      </ng-template>
                      <ng-template #parentLogbookFieldNew>
                        <ng-container *ngIf="data['fieldType'] === 'parent_logbook'; else otherChangeFieldsNew">
                          <strong>{{ (i === 0 ? 'general.from' : 'general.to') | translate }}</strong
                          >: "{{ data['new'] }}"
                        </ng-container>
                      </ng-template>
                      <ng-template #otherChangeFieldsNew> "{{ data['new'] }}"</ng-template>
                      <div class="hiddenForDocument"><br></div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-template>
      <ng-template #reason>
        <ng-container *ngIf="header.value === 'actionReason'; else base">
          <span class="white-space-pre-line overflow-wrap-anywhere">
            {{ item | formSubmissionReason }}
          </span>
        </ng-container>
      </ng-template>
      <ng-template #base>
        <span class="white-space-pre-line">
          {{ item[header.value] }}
        </span>
      </ng-template>
    </td>
  </ng-template>
</datatable>
