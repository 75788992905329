import { Component, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ScwMatFormComponent } from '../scw-mat-ui/scw-mat-form/scw-mat-form.component';
import { OnDestroyDecorator } from '../../decorator/on-destroy-decorator';
import { ITableHeader } from '../../../../constants.model';
import { IFormSubmissionHistory } from '../../../view/home/form-submission-table/form-submission-table.model';
import { HelperService } from '../../service/helper.service';

@OnDestroyDecorator
@Component({
  selector: 'scw-history-table',
  templateUrl: './history-table.component.html',
  styleUrls: ['./history-table.component.scss'],
})
export class HistoryTableComponent {
  @ViewChild('formInstance', { static: true }) formInstance!: ScwMatFormComponent;

  @Input() paginationSize: string = 'lg';
  @Input() headers: ITableHeader[] = [];
  @Input() items: IFormSubmissionHistory[] = [];
  @Input() itemsCount: number = 0;
  @Input() dateFormat: string = 'YYYY-MM-DD HH:mm';
  @Input() timezone: string | null = 'utc';
  @Input() tableClass: string = '';

  protected readonly fieldTypesWithoutOldValues: string[] = ['source', 'parent_logbook'];

  constructor(private readonly translate: TranslateService, private readonly helperService: HelperService) {}

  public yesNo(input: any): string {
    if (input === '') {
      return '';
    }

    if (input === 1 || input === true) {
      return this.translate.instant('general.yes');
    } else {
      return this.translate.instant('general.no');
    }
  }

  public getFile(input: any[]): string {
    if (input.length === 0) {
      return '';
    }
    return input[0]['originalName'];
  }

  public getUrl(input: string): string {
    if (input.substring(0, 7) === 'http://' || input.substring(0, 8) === 'https://') {
      return input;
    } else {
      return 'https://' + input;
    }
  }
}
