import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  IBaseOneResponse,
  IBulkResponseRecord,
  IGetManyResponse,
} from '../../../shared/model/interface/crud-response-interface.model';
import {
  IActivityType,
  IAddActivityType,
  IBulkEditActivityType,
  IValidatePayload,
  IValidationResult,
} from './activity-type.model';
import { IIssuer } from '../../../shared/component/issuer/issuer.model';
import { IIssuerAndReason } from '../../../shared/component/before-action-preparer/before-action-preparer.model';

@Injectable({
  providedIn: 'root',
})
export class ActivityTypeService {
  private readonly routes = {
    ACTIVITY_TYPES: '/activity-types/',
    BULK: 'bulk/',
    VALIDATE: 'validate/',
  };

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getActivityTypes(params: HttpParams): Observable<IGetManyResponse<IActivityType>> {
    return this.http.get<IGetManyResponse<IActivityType>>(`${this.baseUrl}${this.routes.ACTIVITY_TYPES}`, { params });
  }

  public addActivityType(
    activityType: IAddActivityType,
    issuer: IIssuer | null,
  ): Observable<IBaseOneResponse<IActivityType>> {
    return this.http.post<IBaseOneResponse<IActivityType>>(`${this.baseUrl}${this.routes.ACTIVITY_TYPES}`, {
      issuer,
      payload: activityType,
    });
  }

  public editActivityType(
    activityType: IAddActivityType,
    id: number,
    issuer: IIssuer | null,
  ): Observable<IBaseOneResponse<IActivityType>> {
    return this.http.put<IBaseOneResponse<IActivityType>>(`${this.baseUrl}${this.routes.ACTIVITY_TYPES}${id}/`, {
      issuer,
      payload: activityType,
    });
  }

  public deleteActivityType(
    id: number,
    issuerAndReason: IIssuerAndReason,
  ): Observable<IBaseOneResponse<IActivityType>> {
    return this.http.delete<IBaseOneResponse<IActivityType>>(`${this.baseUrl}${this.routes.ACTIVITY_TYPES}${id}/`, {
      body: {
        issuer: issuerAndReason.issuer,
        payload: {
          reason: issuerAndReason.reason,
        },
      },
    });
  }

  public bulkDeleteActivityType(
    activityTypeIds: number[],
    issuerAndReason: IIssuerAndReason,
  ): Observable<IGetManyResponse<IBulkResponseRecord<IActivityType>>> {
    return this.http.delete<IGetManyResponse<IBulkResponseRecord<IActivityType>>>(
      `${this.baseUrl}${this.routes.ACTIVITY_TYPES}${this.routes.BULK}`,
      {
        body: {
          issuer: issuerAndReason.issuer,
          payload: {
            reason: issuerAndReason.reason,
            data: activityTypeIds,
          },
        },
      },
    );
  }

  public bulkEditActivityType(
    activityTypes: IBulkEditActivityType[],
    issuerAndReason: IIssuerAndReason,
  ): Observable<IGetManyResponse<IBulkResponseRecord<IActivityType>>> {
    return this.http.put<IGetManyResponse<IBulkResponseRecord<IActivityType>>>(
      `${this.baseUrl}${this.routes.ACTIVITY_TYPES}${this.routes.BULK}`,
      {
        issuer: issuerAndReason.issuer,
        reason: issuerAndReason.reason,
        payload: activityTypes,
      },
    );
  }

  public validateUtilization(
    payload: IValidatePayload,
    issuer: IIssuer | null,
  ): Observable<IGetManyResponse<IValidationResult>> {
    return this.http.post<IGetManyResponse<IValidationResult>>(
      `${this.baseUrl}${this.routes.ACTIVITY_TYPES}${this.routes.VALIDATE}`,
      {
        issuer,
        payload,
      },
    );
  }
}
