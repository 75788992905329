import { NgControl } from '@angular/forms';
import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[disableControl]',
})
export class DisableControlDirective {
  @Input() set disableControl(condition: boolean) {
    if (this.disabled !== undefined) {
      this.toggleForm(condition);
    }

    this.disabled = condition;
  }
  public disabled: boolean | undefined;

  constructor(private readonly ngControl: NgControl) {}

  private toggleForm(condition: boolean) {
    const action = condition ? 'disable' : 'enable';
    this.ngControl.control?.[action]();
  }

  public ngOnInit() {
    this.toggleForm(Boolean(this.disabled));
  }
}
