import { createAction, props } from '@ngrx/store';
import { IRuleType } from '../../../shared/component/filter/filter.class';

export enum ActionTypes {
  GET_RULE_TYPE_FILTER_DATA = '[RULE TYPE FILTER] GET RULE TYPE FILTER DATA',
  GET_RULE_TYPE_FILTER_DATA_COMPLETED = '[RULE TYPE FILTER] GET RULE TYPE FILTER DATA COMPLETED',
  RESET_RULE_TYPE_FILTER = '[RULE TYPE FILTER] RESET RULE TYPE FILTER',
}

export const getRuleTypeFilterData = createAction(ActionTypes.GET_RULE_TYPE_FILTER_DATA);

export const getRuleTypeFilterDataCompleted = createAction(
  ActionTypes.GET_RULE_TYPE_FILTER_DATA_COMPLETED,
  props<{ payload: IRuleType[] }>(),
);

export const resetRuleTypeFilter = createAction(ActionTypes.RESET_RULE_TYPE_FILTER);
