import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as RuleTypeFilterActions from './rule-type.actions';
import { map, of, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RuleService } from '../../settings/state-rule-and-automation/rules/rule.service';
import { IGetManyResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { IRuleType } from '../../../shared/component/filter/filter.class';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class FilterRuleTypeEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: RuleService,
    private readonly translate: TranslateService,
  ) {}

  getRuleTypeFilterData = createEffect(() =>
    this.actions$.pipe(
      ofType(RuleTypeFilterActions.ActionTypes.GET_RULE_TYPE_FILTER_DATA),
      switchMap(() => {
        return this.service.getRuleTypes().pipe(
          map((response: IGetManyResponse<IRuleType>) =>
            RuleTypeFilterActions.getRuleTypeFilterDataCompleted({
              payload: response.data.map((row: IRuleType) => {
                return {
                  ...row,
                  displayName: this.translate.instant(`filterCard.ruleType.${row.name}`),
                };
              }),
            }),
          ),
          catchError(() => of(RuleTypeFilterActions.getRuleTypeFilterDataCompleted({ payload: [] }))),
        );
      }),
      catchError(() => of(RuleTypeFilterActions.getRuleTypeFilterDataCompleted({ payload: [] }))),
    ),
  );
}
