import { ActionReducer, createReducer, on } from '@ngrx/store';
import * as AutomationActions from './automation.actions';
import { IAutomationState } from './automation.model';

export const initialState: IAutomationState = {
  automationsDataLoading: false,
  automationsDataLoaded: false,
  addAutomationsLoading: false,
  addAutomationsLoaded: false,
  editAutomationsLoading: false,
  editAutomationsLoaded: false,
  deleteAutomationsLoading: false,
  deleteAutomationsLoaded: false,
  bulkDeleteAutomationsLoading: false,
  bulkDeleteAutomationsLoaded: false,
  bulkEditAutomationsLoading: false,
  bulkEditAutomationsLoaded: false,
  automationsData: null,
};

export const automationReducer: ActionReducer<IAutomationState> = createReducer(
  initialState,
  on(AutomationActions.AutomationsDataLoading, (state: IAutomationState) => ({
    ...state,
    automationsDataLoading: true,
    automationsDataLoaded: false,
  })),
  on(AutomationActions.AutomationsDataLoaded, (state: IAutomationState, { payload }) => ({
    ...state,
    automationsDataLoading: false,
    automationsDataLoaded: true,
    AutomationsData: payload.data,
  })),
  on(AutomationActions.AddAutomation, (state: IAutomationState) => ({
    ...state,
    addAutomationsLoading: true,
    addAutomationsLoaded: false,
  })),
  on(AutomationActions.AddAutomationCompleted, (state: IAutomationState) => ({
    ...state,
    addAutomationsLoading: false,
    addAutomationsLoaded: true,
  })),
  on(AutomationActions.EditAutomation, (state: IAutomationState) => ({
    ...state,
    addAutomationsLoading: true,
    addAutomationsLoaded: false,
  })),
  on(AutomationActions.EditAutomationCompleted, (state: IAutomationState) => ({
    ...state,
    editAutomationsLoading: false,
    editAutomationsLoaded: true,
  })),
  on(AutomationActions.DeleteAutomation, (state: IAutomationState) => ({
    ...state,
    deleteAutomationsLoading: true,
    deleteAutomationsLoaded: false,
  })),
  on(AutomationActions.DeleteAutomationCompleted, (state: IAutomationState) => ({
    ...state,
    deleteAutomationsLoading: false,
    deleteAutomationsLoaded: true,
  })),
  on(AutomationActions.BulkDeleteAutomation, (state: IAutomationState) => ({
    ...state,
    bulkDeleteAutomationsLoading: true,
    bulkDeleteAutomationsLoaded: false,
  })),
  on(AutomationActions.BulkDeleteAutomationCompleted, (state: IAutomationState) => ({
    ...state,
    bulkDeleteAutomationsLoading: false,
    bulkDeleteAutomationsLoaded: true,
  })),
  on(AutomationActions.BulkEditAutomation, (state: IAutomationState) => ({
    ...state,
    bulkEditAutomationsLoading: true,
    bulkEditAutomationsLoaded: false,
  })),
  on(AutomationActions.BulkEditAutomationCompleted, (state: IAutomationState) => ({
    ...state,
    bulkEditAutomationsLoading: false,
    bulkEditAutomationsLoaded: true,
  })),
);
