import { Injectable, Type } from '@angular/core';
import { DropdownComponent } from './dropdown/dropdown.component';
import { Store } from '@ngrx/store';
import * as logbookAppReducer from '../../../store/logbook.reducer';
import { FilterClass, IFilterClass, IFormsCRUD, ILogbooksCRUD, IRuleType, IUsersFilterData } from './filter.class';
import { take } from 'rxjs';
import { FilterDateRangePickerComponent } from './filter-date-range-picker/filter-date-range-picker.component';
import { SearchBoxComponent } from './search-box/search-box.component';
import { EArchivedFilter, IHttpOption } from '../../../store/filter/filter.model';
import { ResetUserStore, StartLoadFilterUsers } from '../../../store/filter/user/user.actions';
import { HelperService } from '../../service/helper.service';
import { ISelect } from '../scw-mat-ui/scw-mat-select/scw-mat-select.model';
import {
  EApprovalStatuses,
  ECriticalityStatuses,
  ELogbookApprovalStatuses,
  ELogbookReviewTypes,
} from '../../model/enum/constants';
import { StartLoadFilterForms } from '../../../store/filter/form/form.actions';
import { StartLoadFilterLogbooks } from '../../../store/filter/logbook/logbook.actions';
import * as _ from 'lodash';
import { IClientSettingsState } from '../../../store/settings/client/client.model';
import * as RuleTypeFilterActions from '../../../store/filter/rule-type/rule-type.actions';
import { IRuleTypeFilterState } from '../../../store/filter/rule-type/rule-type.reducer';

export enum ObjectNameProps {
  name = 'name',
  title = 'title',
  username = 'username',
  displayName = 'displayName',
}

export enum DateRangeTypes {
  Today = 'today',
  Yesterday = 'yesterday',
  ThisWeek = 'thisWeek',
  NextSevenDays = 'nextSevenDays',
  LastWeek = 'lastWeek',
  ThisMonth = 'thisMonth',
  NextThirtyDays = 'nextThirtyDays',
  LastMonth = 'lastMonth',
  LastThreeMonths = 'lastThreeMonths',
}

@Injectable({
  providedIn: 'root',
})
export class User extends FilterClass implements IFilterClass {
  public options: IHttpOption = {
    page: 1,
    limit: 500,
    search: undefined,
  };
  public objectNameProp: string = 'fullName';

  constructor(public store: Store<logbookAppReducer.LogbookAppState>) {
    super();
  }

  public init(): void {
    this.dataSubject.next([]);

    this.store.dispatch(new StartLoadFilterUsers(this.options.page, this.options.limit, this.options.search));

    this.subscribe = this.store.select('userFilter').subscribe((state) => {
      if (state.isLoaded && !state.isLoading) {
        this.data = state.data;
        this.dataSubject.next(state.data);
      }
    });
  }

  public getInitialData(): IUsersFilterData[] {
    let data: IUsersFilterData[] = [];
    this.store
      .select('userFilter')
      .pipe(take(1))
      .subscribe((state) => {
        data = state.data;
      });
    return data;
  }

  public getFilterData(options: IHttpOption): void {
    this.options = { ...this.options, ...options };
    this.store.dispatch(new StartLoadFilterUsers(this.options.page, this.options.limit, this.options.search));
  }

  public destroy(): void {
    this.store.dispatch(new ResetUserStore());
  }
}

@Injectable({
  providedIn: 'root',
})
export class Status extends FilterClass implements IFilterClass {
  public statusData = this.helperService.getActiveInactive();

  constructor(public store: Store<logbookAppReducer.LogbookAppState>, public helperService: HelperService) {
    super();
  }

  init(): void {
    this.data = this.statusData;
    this.dataSubject.next(this.data);
  }

  public getInitialData(): ISelect<number, string>[] {
    return this.statusData;
  }
}

@Injectable({
  providedIn: 'root',
})
export class UserStatus extends FilterClass implements IFilterClass {
  public statusData = this.helperService.getUserActiveInactive();

  constructor(public store: Store<logbookAppReducer.LogbookAppState>, public helperService: HelperService) {
    super();
  }

  init(): void {
    this.data = this.statusData;
    this.dataSubject.next(this.data);
  }

  public getInitialData(): ISelect<number, string>[] {
    return this.statusData;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ApprovalStatusWithReject extends FilterClass implements IFilterClass {
  public statusData = this.helperService.translateSelectOptions(
    HelperService.numberEnumToSelectOption(ELogbookApprovalStatuses, 'general.approvalFilterStatuses'),
  );

  constructor(public store: Store<logbookAppReducer.LogbookAppState>, public helperService: HelperService) {
    super();
  }

  init(): void {
    this.data = this.statusData;
    this.dataSubject.next(this.data);
  }

  public getInitialData(): ISelect<number, string>[] {
    return this.statusData;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ApprovalStatus extends FilterClass implements IFilterClass {
  public statusData = this.helperService
    .translateSelectOptions(
      HelperService.numberEnumToSelectOption(ELogbookApprovalStatuses, 'general.approvalFilterStatuses'),
    );

  constructor(public store: Store<logbookAppReducer.LogbookAppState>, public helperService: HelperService) {
    super();
  }

  init(): void {
    this.data = this.statusData;
    this.dataSubject.next(this.data);
  }

  public getInitialData(): ISelect<number, string>[] {
    return this.statusData;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ApprovalStatusesWithConditionalReject extends FilterClass implements IFilterClass {
  public displayRejectButton: boolean = true;
  public statusData: ISelect<number, string>[] = this.helperService.translateSelectOptions(
    HelperService.numberEnumToSelectOption(ELogbookApprovalStatuses, 'general.approvalFilterStatuses'),
  );

  constructor(public store: Store<logbookAppReducer.LogbookAppState>, public helperService: HelperService) {
    super();
  }

  public init(): void {
    this.store
      .select('clientStore')
      .pipe(take(1))
      .subscribe((state: IClientSettingsState) => {
        this.displayRejectButton = state.clientCommonInformation?.meta?.displayRejectButton ?? true;
      });
    if (!this.displayRejectButton) {
      this.data = this.statusData.filter(
        (status: ISelect<number, string>) => status.id !== ELogbookApprovalStatuses.REJECTED,
      );
    } else {
      this.data = this.statusData;
    }
    this.dataSubject.next(this.data);
  }

  public getInitialData(): ISelect<number, string>[] {
    return this.statusData;
  }
}

@Injectable({
  providedIn: 'root',
})
export class LogApprovalStatus extends FilterClass implements IFilterClass {
  public statusData = this.helperService
    .translateSelectOptions(HelperService.numberEnumToSelectOption(EApprovalStatuses, 'general.approvalFilterStatuses'))
    .filter(
      (status: ISelect<number, string>) =>
        status.id !== EApprovalStatuses.FLAGGED_TO_BE_OBSOLETE &&
        status.id !== EApprovalStatuses.OBSOLETE &&
        status.id !== EApprovalStatuses.ACTIVE,
    );

  constructor(public store: Store<logbookAppReducer.LogbookAppState>, public helperService: HelperService) {
    super();
  }

  init(): void {
    this.data = this.statusData;
    this.dataSubject.next(this.data);
  }

  public getInitialData(): ISelect<number, string>[] {
    return this.statusData;
  }
}

@Injectable({
  providedIn: 'root',
})
export class Logbook extends FilterClass implements IFilterClass {
  public objectNameProp: string = ObjectNameProps.name;
  public options: IHttpOption = {
    page: 1,
    limit: 5000,
  };

  constructor(public store: Store<logbookAppReducer.LogbookAppState>, public helperService: HelperService) {
    super();
  }

  init(): void {
    this.subscribe = this.store.select('logbookFilter').subscribe((state) => {
      if (!state.isLoaded && !state.isLoading) {
        this.store.dispatch(
          new StartLoadFilterLogbooks(this.options.page, this.options.limit, [], {
            sort: null,
            direction: null,
          }, EArchivedFilter.ALL),
        );
      }
      if (state.isLoaded && !state.isLoading) {
        this.data = this.addLogbookId(state.data);
        this.dataSubject.next(this.data);
      }
    });
  }

  private addLogbookId(data: ILogbooksCRUD[]): ILogbooksCRUD[] {
    let newData: ILogbooksCRUD[] = [];
    for (const row of data) {
      let newRow = _.cloneDeep(row);
      newRow.name = row.logbookId + ' - ' + row.name;
      newData.push(newRow);
    }
    return newData;
  }

  public getInitialData(): ILogbooksCRUD[] {
    let data;
    this.store
      .select('logbookFilter')
      .pipe(take(1))
      .subscribe((state) => {
        data = state.data;
      });

    // todo: this needs to be removed (Engin)
    // @ts-ignore
    return data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class LogbookReviewType extends FilterClass implements IFilterClass {
  public statusData = this.helperService.translateSelectOptions(
    HelperService.numberEnumToSelectOption(ELogbookReviewTypes, 'general.logbookTypes'),
  );

  constructor(public store: Store<logbookAppReducer.LogbookAppState>, public helperService: HelperService) {
    super();
  }

  init(): void {
    this.data = this.statusData;
    this.dataSubject.next(this.data);
  }

  public getInitialData(): ISelect<number, string>[] {
    return this.statusData;
  }
}

@Injectable({
  providedIn: 'root',
})
export class FormReviewType extends FilterClass implements IFilterClass {
  public statusData = this.helperService.translateSelectOptions(
    HelperService.numberEnumToSelectOption(ELogbookReviewTypes, 'general.formTypes'),
  );

  constructor(public store: Store<logbookAppReducer.LogbookAppState>, public helperService: HelperService) {
    super();
  }

  init(): void {
    this.data = this.statusData;
    this.dataSubject.next(this.data);
  }

  public getInitialData(): ISelect<number, string>[] {
    return this.statusData;
  }
}

@Injectable({
  providedIn: 'root',
})
export class Form extends FilterClass implements IFilterClass {
  public objectNameProp: string = ObjectNameProps.name;
  public options: IHttpOption = {
    page: 1,
    limit: 0,
  };

  constructor(public store: Store<logbookAppReducer.LogbookAppState>, public helperService: HelperService) {
    super();
  }

  init(): void {
    this.subscribe = this.store.select('formFilter').subscribe((state) => {
      if (!state.isLoaded && !state.isLoading) {
        this.store.dispatch(new StartLoadFilterForms(this.options.page, this.options.limit, [], EArchivedFilter.ALL));
      }
      if (state.isLoaded && !state.isLoading) {
        this.data = this.addFormId(state.data);

        this.dataSubject.next(this.data);
      }
    });
  }

  private addFormId(data: IFormsCRUD[]): IFormsCRUD[] {
    let newData: IFormsCRUD[] = [];
    for (const row of data) {
      let newRow = _.cloneDeep(row);
      newRow.name = row.formId + ' - ' + row.name;
      newData.push(newRow);
    }
    return newData;
  }

  public getInitialData(): IFormsCRUD[] {
    let data;
    this.store
      .select('formFilter')
      .pipe(take(1))
      .subscribe((state) => {
        data = state.data;
      });

    // todo: this needs to be removed (Engin)
    // @ts-ignore
    return data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CriticalField extends FilterClass implements IFilterClass {
  public statusData = this.helperService.translateSelectOptions(
    HelperService.numberEnumToSelectOption(ECriticalityStatuses, 'general.criticalityStatuses'),
  );

  constructor(public store: Store<logbookAppReducer.LogbookAppState>, public helperService: HelperService) {
    super();
  }

  init(): void {
    this.data = this.statusData;
    this.dataSubject.next(this.data);
  }

  public getInitialData(): ISelect<number, string>[] {
    return this.statusData;
  }
}

@Injectable({
  providedIn: 'root',
})
export class LogbookAndFormStatus extends FilterClass implements IFilterClass {
  public statusData = this.helperService.getLogBookStatus().filter((item: ISelect<number, string>) => item.id !== -7);

  constructor(public store: Store<logbookAppReducer.LogbookAppState>, public helperService: HelperService) {
    super();
  }

  init(): void {
    this.data = this.statusData;
    this.dataSubject.next(this.data);
  }

  public getInitialData(): ISelect<number, string>[] {
    return this.statusData;
  }
}

@Injectable({
  providedIn: 'root',
})
export class StandardVersionStatus extends FilterClass implements IFilterClass {
  public statusData = this.helperService
    .getDefaultStatusValues()
    .filter((item: ISelect<number, string>) => item.id !== -7);

  constructor(public store: Store<logbookAppReducer.LogbookAppState>, public helperService: HelperService) {
    super();
  }

  init(): void {
    this.data = this.statusData;
    this.dataSubject.next(this.data);
  }

  public getInitialData(): ISelect<number, string>[] {
    return this.statusData;
  }
}

@Injectable({
  providedIn: 'root',
})
export class FormVersionStatus extends FilterClass implements IFilterClass {
  public statusData: ISelect<number, string>[] = [];

  constructor(public store: Store<logbookAppReducer.LogbookAppState>, public helperService: HelperService) {
    super();
  }

  init(): void {
    this.statusData = this.helperService.getFormStatusValues();
    this.data = this.statusData;
    this.dataSubject.next(this.data);
  }

  public getInitialData(): ISelect<number, string>[] {
    return this.statusData;
  }
}

@Injectable({
  providedIn: 'root',
})
export class MasterDataStatus extends FilterClass implements IFilterClass {
  public statusData = this.helperService
    .getDefaultStatusValues()
    .filter((item: ISelect<number, string>) => item.id !== -7);

  constructor(public store: Store<logbookAppReducer.LogbookAppState>, public helperService: HelperService) {
    super();
  }

  init(): void {
    this.data = this.statusData;
    this.dataSubject.next(this.data);
  }

  public getInitialData(): ISelect<number, string>[] {
    return this.statusData;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuditTrailAction extends FilterClass implements IFilterClass {
  public statusData: ISelect<string, string>[] = [];

  constructor(public store: Store<logbookAppReducer.LogbookAppState>, public helperService: HelperService) {
    super();
  }

  init(): void {
    this.statusData = this.helperService.getAuditTrailActions();
    this.data = this.statusData;
    this.dataSubject.next(this.data);
  }

  public getInitialData(): ISelect<string, string>[] {
    return this.statusData;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuditTrailLocation extends FilterClass implements IFilterClass {
  public statusData = this.helperService.getAuditTrailLocations();

  constructor(public store: Store<logbookAppReducer.LogbookAppState>, public helperService: HelperService) {
    super();
  }

  init(): void {
    this.data = this.statusData;
    this.dataSubject.next(this.data);
  }

  public getInitialData(): ISelect<string, string>[] {
    return this.statusData;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ApprovalStatusWithoutRejected extends FilterClass implements IFilterClass {
  public statusData = this.helperService
    .translateSelectOptions(
      HelperService.numberEnumToSelectOption(ELogbookApprovalStatuses, 'general.approvalFilterStatuses'),
    )
    .filter((status: ISelect<number, string>) => status.id !== ELogbookApprovalStatuses.REJECTED);

  constructor(public store: Store<logbookAppReducer.LogbookAppState>, public helperService: HelperService) {
    super();
  }

  init(): void {
    this.data = this.statusData;
    this.dataSubject.next(this.data);
  }

  public getInitialData(): ISelect<number, string>[] {
    return this.statusData;
  }
}

@Injectable({
  providedIn: 'root',
})
export class RuleType extends FilterClass implements IFilterClass {
  public objectNameProp: string = ObjectNameProps.displayName;

  constructor(public store: Store<logbookAppReducer.LogbookAppState>) {
    super();
  }

  public init(): void {
    this.dataSubject.next([]);

    this.store.dispatch(RuleTypeFilterActions.getRuleTypeFilterData());

    this.subscribe = this.store.select('ruleTypeFilter').subscribe((state) => {
      if (state.isLoaded && !state.isLoading) {
        this.data = state.data;
        this.dataSubject.next(state.data);
      }
    });
  }

  public getFilterData(): void {
    this.store.dispatch(RuleTypeFilterActions.getRuleTypeFilterData());
  }

  public getInitialData(): IRuleType[] {
    let data: IRuleType[] = [];

    this.store
      .select('ruleTypeFilter')
      .pipe(take(1))
      .subscribe((state: IRuleTypeFilterState): void => {
        data = state.data;
      });

    return data;
  }

  public destroy(): void {
    this.store.dispatch(RuleTypeFilterActions.resetRuleTypeFilter());
  }
}

export type FilterableObjectTypes =
  | User
  | Status
  | UserStatus
  | LogbookAndFormStatus
  | StandardVersionStatus
  | ApprovalStatus
  | ApprovalStatusWithReject
  | Logbook
  | Form
  | CriticalField
  | LogbookReviewType
  | AuditTrailAction
  | AuditTrailLocation
  | ApprovalStatusWithoutRejected
  | RuleType;

export type TypeFilterableObjects = Type<FilterableObjectTypes>;

export type FilterableComponents = DropdownComponent | FilterDateRangePickerComponent | SearchBoxComponent;
