<mat-progress-bar
  mode="indeterminate"
  [color]="'primary'"
  [hidden]="!isFeedTokenLoading$ && !notificationsLoading$"
></mat-progress-bar>
<ng-template [ngIf]="notificationList$.results.length > 0">
  <cdk-virtual-scroll-viewport itemSize="10" class="scroll-viewport">
    <ul>
      <li
        *cdkVirtualFor="let notification of notificationList$.results"
        class="notification-item-container"
        [style.background-color]="notification.is_seen ? 'white' : '#ebebeb'"
      >
        <div class="notification-item">
          <div [style.background-color]="notification.scw_iconBgColor" class="notification-icon">
            <i
              class="fa fa-{{ notification.scw_iconName }}"
              aria-hidden="true"
              [style.color]="notification.scw_iconColor"
            ></i>
          </div>
          <div class="notification-text">
            <div [innerHtml]="notification.hrText"></div>
          </div>
          <div class="notification-time">
            {{ notification.hrTime }}
          </div>
        </div>
      </li>
    </ul>
  </cdk-virtual-scroll-viewport>
  <button *ngIf="hasNextNotification" class="btn btn-default btn-block" (click)="loadMoreNotifications()">
    {{ 'notification.loadButton' | translate }}
  </button>
</ng-template>
<ng-template [ngIf]="!isFeedTokenLoading$ && !notificationsLoading$ && notificationList$.results.length <= 0">
  <div class="info-container">
    {{ 'notification.noOlderNotifications' | translate }}
  </div>
</ng-template>
<ng-template [ngIf]="isFeedTokenLoading$ || notificationsLoading$">
  <div class="info-container">
    {{ 'notification.loading' | translate }}
  </div>
</ng-template>
