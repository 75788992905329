import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, UntypedFormControl } from '@angular/forms';
import { replaceDateStringForValidator } from '../../helper/date';

@Directive({
  selector: '[customMaxDate][formControlName],[customMaxDate][formControl],[customMaxDate][ngModel]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: CustomMaxDateDirective,
    multi: true,
  }],
})

export class CustomMaxDateDirective implements Validator {
  @Input() customMaxDate: any;

  validate(input: UntypedFormControl): { customMaxDate: boolean; [key: string]: any } | null {
    let customValue = input.value;

    if (
      customValue === null || customValue === undefined ||
      customValue === '' || this.customMaxDate === null ||
      this.customMaxDate === undefined || this.customMaxDate === ''
    ) {
      return null;
    }

    customValue = new Date(replaceDateStringForValidator(customValue));

    if (typeof this.customMaxDate === 'string') {
      this.customMaxDate = new Date(replaceDateStringForValidator(this.customMaxDate));
    } else {
      this.customMaxDate = new Date(this.customMaxDate);
    }

    return (customValue.getTime() > this.customMaxDate.getTime()) ? { customMaxDate : true } : null;
  }
}
