import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'locale',
})
export class LocalePipe implements PipeTransform {
  transform(value: moment.Moment, locale: string): moment.Moment {
    return value.locale(locale);
  }
}
