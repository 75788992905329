import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LogbookAppState } from '../../../logbook.reducer';
import { AutomationService } from './automation.service';
import * as AutomationActions from './automation.actions';
import { map, of, switchMap, tap } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { ServiceUtilities } from '../../../../shared/helper/service-utilities';
import {
  IBaseOneResponse,
  IBulkResponseRecord,
  IGetManyResponse,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { IAddAutomation, IAutomation, IBulkEditAutomation } from './automation.model';
import { catchError } from 'rxjs/operators';
import { IIssuer } from '../../../../shared/component/issuer/issuer.model';
import { IIssuerAndReason } from '../../../../shared/component/before-action-preparer/before-action-preparer.model';
import * as AppActions from '../../../app/actions';
import { ITableQueryWithIsActive } from '../../../../shared/model/interface/common-page.model';

@Injectable()
export class AutomationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<LogbookAppState>,
    private readonly service: AutomationService,
  ) {}

  getAutomationData = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationActions.ActionTypes.AUTOMATION_DATA_LOADING),
      switchMap((payload: { tableQuery: ITableQueryWithIsActive }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const httpParams: HttpParams = ServiceUtilities.prepareGenericHttpParamsForRequest({
          page: payload.tableQuery.page,
          rowsPerPage: payload.tableQuery.rowsPerPage,
          sort: payload.tableQuery.sort,
          ...(payload.tableQuery.search && { search: payload.tableQuery.search }),
          ...(payload.tableQuery.isActive?.length && {
            isActive: Boolean(payload.tableQuery.isActive[0]),
          }),
        });

        return this.service.getAutomations(httpParams).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IGetManyResponse<IAutomation>) =>
            AutomationActions.AutomationsDataLoaded({ payload: response }),
          ),
          catchError((error) => of(AutomationActions.FetchError({ error }))),
        );
      }),
      catchError((error) => of(AutomationActions.FetchError({ error }))),
    ),
  );

  addAutomation = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationActions.ActionTypes.ADD_AUTOMATION),
      switchMap((payload: { activityType: IAddAutomation; issuer: IIssuer | null }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addAutomation(payload.activityType, payload.issuer).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IBaseOneResponse<IAutomation>) =>
            AutomationActions.AddAutomationCompleted({ payload: response.data }),
          ),
          catchError((error) => of(AutomationActions.FetchError({ error }))),
        );
      }),
      catchError((error) => of(AutomationActions.FetchError({ error }))),
    ),
  );

  editAutomation = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationActions.ActionTypes.EDIT_AUTOMATION),
      switchMap((payload: { activityType: IAddAutomation; id: number; issuer: IIssuer | null }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editAutomation(payload.activityType, payload.id, payload.issuer).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IBaseOneResponse<IAutomation>) =>
            AutomationActions.EditAutomationCompleted({ payload: response.data }),
          ),
          catchError((error) => of(AutomationActions.FetchError({ error }))),
        );
      }),
      catchError((error) => of(AutomationActions.FetchError({ error }))),
    ),
  );

  deleteAutomation = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationActions.ActionTypes.DELETE_AUTOMATION),
      switchMap((payload: { id: number; issuerAndReason: IIssuerAndReason }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.deleteAutomation(payload.id, payload.issuerAndReason).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IBaseOneResponse<IAutomation>) =>
            AutomationActions.DeleteAutomationCompleted({ payload: response.data }),
          ),
          catchError((error) => of(AutomationActions.FetchError({ error }))),
        );
      }),
      catchError((error) => of(AutomationActions.FetchError({ error }))),
    ),
  );

  bulkDeleteAutomation = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationActions.ActionTypes.BULK_DELETE_AUTOMATION),
      switchMap((payload: { activityTypeIds: number[]; issuerAndReason: IIssuerAndReason }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.bulkDeleteAutomation(payload.activityTypeIds, payload.issuerAndReason).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IGetManyResponse<IBulkResponseRecord<IAutomation>>) =>
            AutomationActions.BulkDeleteAutomationCompleted({ payload: response.data }),
          ),
          catchError((error) => {
            return of(AutomationActions.FetchError({ error }));
          }),
        );
      }),
      catchError((error) => {
        return of(AutomationActions.FetchError({ error }));
      }),
    ),
  );

  bulkEditAutomation = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationActions.ActionTypes.BULK_EDIT_AUTOMATION),
      switchMap((payload: { activityTypes: IBulkEditAutomation[]; issuerAndReason: IIssuerAndReason }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.bulkEditAutomation(payload.activityTypes, payload.issuerAndReason).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IGetManyResponse<IBulkResponseRecord<IAutomation>>) =>
            AutomationActions.BulkEditAutomationCompleted({ payload: response.data }),
          ),
          catchError((error) => of(AutomationActions.FetchError({ error }))),
        );
      }),
      catchError((error) => of(AutomationActions.FetchError({ error }))),
    ),
  );
}
