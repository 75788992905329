import { Injectable, Provider } from '@angular/core';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE, OwlDateTimeIntl } from 'ng-pick-datetime-ex';
import { HelperService } from './app/shared/service/helper.service';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { CustomDateFormat } from './app/store/user/model';
import { IMenuItems, IPage } from './constants.model';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { IDatatableOutputSort } from './app/shared/component/datatable/datatable.model';
import { Moment } from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { DateRangeTypes } from './app/shared/component/filter/filterable-objects.class';
import * as AppActions from './app/store/app/actions';
import { BehaviorSubject, of } from 'rxjs';
import { IUserMeta } from './app/store/settings/users/users.model';
import { ERightOperators, ERights } from './app/store/main/main.model';

const OEE_TOOL_NAME: string = 'Logbook';
export const BROWSER_TITLE_SUFFIX: string = ` | ${OEE_TOOL_NAME}`;
export const EXCEL_DATE_FORMAT: string = 'MM.DD.YYYY';
export const RADIX: number = 10;
export const STATIC_MAX_LIMIT_OF_CRUD = 500;

export function setLocale(helperService: HelperService): string | null {
  return helperService.setLocale();
}

export function setCustomDateFormats(helperService: HelperService): CustomDateFormat {
  return helperService.setCustomDateFormats();
}

export const OWL_DATE_TIME_PROVIDERS: Provider[] = [
  { provide: OWL_DATE_TIME_LOCALE, deps: [HelperService], useFactory: setLocale },
  { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
  { provide: OWL_DATE_TIME_FORMATS, deps: [HelperService], useFactory: setCustomDateFormats },
];

export enum DefaultQueryParameters {
  LIMIT = 100,
}

export enum ActiveStatuses {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum EStatuses {
  ACTIVE = 1,
  INACTIVE = 2,
}

export enum StatusesLanguageKeys {
  ACTIVE = 'general.active',
  INACTIVE = 'general.inactive',
}

export enum YesNoLanguageKeys {
  True = 'general.yes',
  False = 'general.no',
}

export enum TrueFalse {
  True = 1,
  False = 0,
}

export enum EYesNo {
  YES = 1,
  NO = 0,
}

export enum EMultipleCheckIns {
  YES = '1',
  NO = '0',
  SITE_DEFAULT = '2',
}

export enum EMyTaskTabs {
  LOGS_FORM_ENTRIES = 'logs-form-entries',
  LOGBOOK_TASKS = 'logbook-tasks',
  FORM_TASKS = 'form-tasks',
}

const DEFAULT_ICON: string = 'icon-layout-sidebar-left';
const EDIT_ICON: string = 'fas fa-edit';
const COGS_ICON: string = 'fas fa-cogs';
const CALENDAR_ICON: string = 'fa fa-calendar';
const CUBE_ICON: string = 'fas fa-cube';
const COG_ICON: string = 'fa fa-cog';
const USER_CLOCK_ICON: string = 'fas fa-user-clock';
const COPY_ICON: string = 'fa fa-copy fa-flip-horizontal';
const BARCODE_ICON: string = 'fas fa-barcode';
const LIST_ICON: string = 'fas fa-list-alt';
const FILE_SIGN_ICON: string = 'fas fa-file-signature';
const HOURGLASS_HALF_ICON: string = 'fas fa-hourglass-half';
const FILE_ICON: string = 'fas fa-file-alt';
const BAR_CHART_ICON: string = 'fa fa-bar-chart';
const TASKS_ICON: string = 'fas fa-tasks';
const FORMS_ICON: string = 'fas fa-user-cog';
const CLIENT_ICON: string = 'fas fa-user-friends';
const USER_ICON: string = 'fas fa-user';
const SCOPES_ICON: string = 'fa fa-user';

export const smallModal: NgbModalOptions = {
  keyboard: false,
  backdrop: 'static',
  windowClass: 'scw-modal-sm',
};

export const mediumModal: NgbModalOptions = {
  keyboard: false,
  backdrop: 'static',
  windowClass: 'scw-modal-md',
};

export const PAGES: IPage[] = [
  {
    title: 'client-selection',
    titleKey: 'client-selection',
    icon: DEFAULT_ICON,
  },
  {
    title: 'home',
    titleKey: 'home',
    icon: DEFAULT_ICON,
  },
  {
    title: 'my-tasks-logs-form-entries',
    titleKey: 'my-tasks-logs-form-entries',
    icon: DEFAULT_ICON,
  },
  {
    title: 'my-tasks-form-tasks',
    titleKey: 'my-tasks-form-tasks',
    icon: DEFAULT_ICON,
  },
  {
    title: 'my-tasks-logbook-tasks',
    titleKey: 'my-tasks-logbook-tasks',
    icon: DEFAULT_ICON,
  },
  {
    title: 'forms',
    titleKey: 'forms',
    icon: COG_ICON,
  },
  {
    title: 'form-versions',
    titleKey: 'form-versions',
    icon: COG_ICON,
  },
  {
    title: 'form-version-details',
    titleKey: 'form-version-details',
    icon: FORMS_ICON,
  },
  {
    title: 'role',
    titleKey: 'roles',
    icon: COG_ICON,
  },
  {
    title: 'client',
    titleKey: 'client',
    icon: COG_ICON,
  },
  {
    title: 'users',
    titleKey: 'users',
    icon: COG_ICON,
  },
  {
    title: 'scopes',
    titleKey: 'scopes',
    icon: COG_ICON,
  },
  {
    title: 'workflow',
    titleKey: 'workflow',
    icon: COG_ICON,
  },
  {
    title: 'Form Template Reports',
    titleKey: 'formTemplates',
    icon: FILE_ICON,
  },
  {
    title: 'Logbook Template Reports',
    titleKey: 'logbookTemplates',
    icon: FILE_ICON,
  },
  {
    title: 'logbookSettings',
    titleKey: 'logbookSettings',
    icon: COG_ICON,
  },
  {
    title: 'Master Data',
    titleKey: 'master-data',
    icon: COG_ICON,
  },
  {
    title: 'Knowledge Base',
    titleKey: 'knowledgeBase',
    icon: FILE_ICON,
    relatedModule: 'knowledgeBase',
  },
  {
    title: 'Logs Table Configuration',
    titleKey: 'logsTableConfiguration',
    icon: COG_ICON,
    relatedModule: 'logsTableConfiguration',
  },
  {
    title: 'Activity Types',
    titleKey: 'activityTypes',
    icon: COG_ICON,
  },
  {
    title: 'States',
    titleKey: 'states',
    icon: COG_ICON,
    relatedModule: 'states',
  },
  {
    title: 'State Rules & Automations',
    titleKey: 'stateRulesAndAutomations',
    icon: COG_ICON,
  },
];

export const MENU_INFORMATION: IMenuItems = {
  menus: [
    {
      key: '1',
      id: 1,
      name: 'home',
      staticName: null,
      parentId: 0,
      link: 'home',
      orders: 1,
      status: true,
      icon: 'fas fa-bell',
      external: false,
      inProgress: false,
      isNew: false,
      appId: 1,
      isUpgradeBadgeEnabled: false,
      requiredRights: { rights: [], operator: ERightOperators.OR },
    },
    {
      key: '2',
      id: 2,
      name: 'myTasks',
      staticName: null,
      parentId: 0,
      link: 'my-tasks',
      orders: 2,
      status: true,
      icon: 'fas fa-tasks',
      external: false,
      inProgress: false,
      isNew: true,
      appId: 1,
      isUpgradeBadgeEnabled: true,
      requiredRights: { rights: [ERights.TASK_VIEW], operator: ERightOperators.OR },
    },
    {
      key: '3',
      id: 3,
      name: 'reports',
      staticName: null,
      parentId: 0,
      link: '#',
      orders: 3,
      status: true,
      icon: 'fas fa-file',
      external: false,
      inProgress: false,
      isNew: false,
      appId: 1,
      isUpgradeBadgeEnabled: false,
      requiredRights: {
        rights: [
          ERights.REPORT_LOGS_VIEW,
          ERights.REPORT_MY_FORM_ENTRIES_VIEW,
          ERights.REPORT_LOGBOOK_TEMPLATES_VIEW,
          ERights.REPORT_FORM_TEMPLATES_VIEW,
        ],
        operator: ERightOperators.OR,
      },
    },
    {
      key: '4',
      id: 4,
      name: 'settings',
      staticName: null,
      parentId: 0,
      link: '#',
      orders: 4,
      status: true,
      icon: 'fas fa-gear',
      external: false,
      inProgress: false,
      isNew: false,
      appId: 1,
      isUpgradeBadgeEnabled: false,
      requiredRights: { rights: [ERights.SETTING_VIEW], operator: ERightOperators.OR },
    },
    {
      key: '6',
      id: 6,
      name: 'auditLogs',
      staticName: null,
      parentId: 0,
      link: 'audit-trail',
      orders: 5,
      status: true,
      icon: 'fas fa-history',
      external: false,
      inProgress: false,
      isNew: true,
      appId: 1,
      isUpgradeBadgeEnabled: true,
      requiredRights: { rights: [ERights.REPORT_AUDITLOG_VIEW], operator: ERightOperators.OR },
    },
    {
      key: '7',
      id: 7,
      name: 'clientSettings',
      staticName: null,
      parentId: 4,
      link: 'settings/client',
      orders: 1,
      status: true,
      icon: 'fas fa-cog',
      external: false,
      inProgress: false,
      isNew: false,
      appId: 1,
      isUpgradeBadgeEnabled: false,
      requiredRights: { rights: [ERights.SETTING_VIEW], operator: ERightOperators.OR },
    },
    {
      key: '8',
      id: 8,
      name: 'roleSettings',
      staticName: null,
      parentId: 4,
      link: 'settings/roles',
      orders: 2,
      status: true,
      icon: 'fas fa-cog',
      external: false,
      inProgress: false,
      isNew: false,
      appId: 1,
      isUpgradeBadgeEnabled: false,
      requiredRights: { rights: [ERights.SETTING_VIEW], operator: ERightOperators.OR },
    },
    {
      key: '9',
      id: 9,
      name: 'scopeSettings',
      staticName: null,
      parentId: 4,
      link: 'settings/scopes',
      orders: 3,
      status: true,
      icon: 'fas fa-cog',
      external: false,
      inProgress: false,
      isNew: false,
      appId: 1,
      isUpgradeBadgeEnabled: false,
      requiredRights: { rights: [ERights.SETTING_VIEW], operator: ERightOperators.OR },
    },
    {
      key: '10',
      id: 10,
      name: 'userSettings',
      staticName: null,
      parentId: 4,
      link: 'settings/users',
      orders: 4,
      status: true,
      icon: 'fas fa-cog',
      external: false,
      inProgress: false,
      isNew: false,
      appId: 1,
      isUpgradeBadgeEnabled: false,
      requiredRights: { rights: [ERights.SETTING_VIEW], operator: ERightOperators.OR },
    },
    {
      key: '11',
      id: 11,
      name: 'workflowSettings',
      staticName: null,
      parentId: 4,
      link: 'settings/workflows',
      orders: 5,
      status: true,
      icon: 'fas fa-cog',
      external: false,
      inProgress: false,
      isNew: false,
      appId: 1,
      isUpgradeBadgeEnabled: false,
      requiredRights: { rights: [ERights.SETTING_VIEW], operator: ERightOperators.OR },
    },
    {
      key: '12',
      id: 12,
      name: 'masterData',
      staticName: null,
      parentId: 4,
      link: 'settings/master-data',
      orders: 6,
      status: true,
      icon: 'fas fa-cog',
      external: false,
      inProgress: false,
      isNew: false,
      appId: 1,
      isUpgradeBadgeEnabled: false,
      requiredRights: {
        rights: [ERights.SETTING_VIEW],
        operator: ERightOperators.OR,
      },
    },
    {
      key: '13',
      id: 13,
      name: 'logbookSettings',
      staticName: null,
      parentId: 4,
      link: 'settings/logbooks',
      orders: 7,
      status: true,
      icon: 'fas fa-cog',
      external: false,
      inProgress: false,
      isNew: false,
      appId: 1,
      isUpgradeBadgeEnabled: false,
      requiredRights: { rights: [ERights.SETTING_VIEW], operator: ERightOperators.OR },
    },
    {
      key: '14',
      id: 14,
      name: 'formSettings',
      staticName: null,
      parentId: 4,
      link: 'settings/forms',
      orders: 8,
      status: true,
      icon: 'fas fa-cog',
      external: false,
      inProgress: false,
      isNew: false,
      appId: 1,
      isUpgradeBadgeEnabled: false,
      requiredRights: { rights: [ERights.SETTING_VIEW], operator: ERightOperators.OR },
    },
    {
      key: '15',
      id: 15,
      name: 'logs',
      staticName: null,
      parentId: 3,
      link: 'reports/logs',
      orders: 1,
      status: true,
      icon: 'fas fa-file',
      external: false,
      inProgress: false,
      isNew: false,
      appId: 1,
      isUpgradeBadgeEnabled: false,
      requiredRights: {
        rights: [ERights.REPORT_LOGS_VIEW],
        operator: ERightOperators.OR,
      },
    },
    {
      key: '16',
      id: 16,
      name: 'myFormEntryReports',
      staticName: null,
      parentId: 3,
      link: 'reports/my-form-entry-reports',
      orders: 2,
      status: true,
      icon: 'fas fa-file-alt',
      external: false,
      inProgress: false,
      isNew: false,
      appId: 1,
      isUpgradeBadgeEnabled: false,
      requiredRights: {
        rights: [ERights.REPORT_MY_FORM_ENTRIES_VIEW],
        operator: ERightOperators.OR,
      },
    },
    {
      key: '17',
      id: 17,
      name: 'formEntries',
      staticName: null,
      parentId: -1,
      link: 'reports/form-entries',
      orders: 0,
      status: true,
      icon: 'fas fa-file',
      external: false,
      inProgress: false,
      isNew: false,
      appId: 1,
      isUpgradeBadgeEnabled: false,
      requiredRights: {
        rights: [ERights.REPORT_MY_FORM_ENTRIES_VIEW],
        operator: ERightOperators.OR,
      },
    },
    {
      key: '18',
      id: 18,
      name: 'logbookTemplates',
      staticName: null,
      parentId: 3,
      link: 'reports/logbook-templates',
      orders: 3,
      status: true,
      icon: 'fas fa-file',
      external: false,
      inProgress: false,
      isNew: false,
      appId: 1,
      isUpgradeBadgeEnabled: false,
      requiredRights: {
        rights: [ERights.REPORT_LOGBOOK_TEMPLATES_VIEW],
        operator: ERightOperators.OR,
      },
    },
    {
      key: '19',
      id: 19,
      name: 'formTemplates',
      staticName: null,
      parentId: 3,
      link: 'reports/form-templates',
      orders: 4,
      status: true,
      icon: 'fas fa-users',
      external: false,
      inProgress: false,
      isNew: false,
      appId: 1,
      isUpgradeBadgeEnabled: false,
      requiredRights: {
        rights: [ERights.REPORT_FORM_TEMPLATES_VIEW],
        operator: ERightOperators.OR,
      },
    },
    {
      key: '20',
      id: 20,
      name: 'logsTableConfiguration',
      staticName: null,
      parentId: 4,
      link: 'settings/logs-table-configuration',
      orders: 9,
      status: true,
      icon: 'fas fa-cog',
      external: false,
      inProgress: false,
      isNew: false,
      appId: 1,
      isUpgradeBadgeEnabled: false,
      requiredRights: {
        rights: [ERights.SETTING_VIEW],
        operator: ERightOperators.OR,
      },
    },
    {
      key: '21',
      id: 21,
      name: 'stateSettings',
      staticName: null,
      parentId: 4,
      link: 'settings/states',
      orders: 10,
      status: true,
      icon: 'fas fa-cog',
      external: false,
      inProgress: false,
      isNew: false,
      appId: 1,
      isUpgradeBadgeEnabled: false,
      requiredRights: { rights: [ERights.SETTING_VIEW], operator: ERightOperators.OR },
    },
    {
      key: '22',
      id: 22,
      name: 'activityTypes',
      staticName: null,
      parentId: 4,
      link: 'settings/activity-types',
      orders: 10,
      status: true,
      icon: 'fas fa-gear',
      external: false,
      inProgress: false,
      isNew: false,
      appId: 1,
      isUpgradeBadgeEnabled: false,
      requiredRights: { rights: [ERights.SETTING_VIEW], operator: ERightOperators.OR },
    },
    {
      key: '23',
      id: 23,
      name: 'stateRulesAndAutomations',
      staticName: null,
      parentId: 4,
      link: 'settings/state-rules-and-automations',
      orders: 10,
      status: true,
      icon: 'fas fa-gear',
      external: false,
      inProgress: false,
      isNew: false,
      appId: 1,
      isUpgradeBadgeEnabled: false,
      requiredRights: { rights: [ERights.SETTING_VIEW], operator: ERightOperators.OR },
    },
  ],
  clientLogo: null,
  singleSiteAndLine: false,
};

export enum EToastType {
  success = 'success',
  error = 'error',
  info = 'info',
  warning = 'warning',
}

export enum GenericModalTypes {
  add = 'add',
  edit = 'edit',
  bulkEdit = 'bulkEdit',
  delete = 'delete',
}

export const defaultPageSort: IDatatableOutputSort = {
  type: 'descending',
  column: 'id',
};

export type DateRanges = {
  [key in
    | 'today'
    | 'todayWhole'
    | 'yesterday'
    | 'thisWeek'
    | 'lastWeek'
    | 'thisMonth'
    | 'lastMonth'
    | 'thisYear'
    | 'lastYear'
    | 'nextSevenDays'
    | 'nextThirtyDays'
    | 'lastThreeMonths'
    | DateRangeTypes]?: [Moment, Moment];
};

export const genericModalOptions: NgbModalOptions = {
  size: 'lg',
  windowClass: 'full-modal',
  keyboard: false,
  backdrop: 'static',
};

export enum ELanguages {
  EN = 'en',
  TR = 'tr',
  DE = 'de',
  ES = 'es',
  FR = 'fr',
  IT = 'it',
  PL = 'pl',
}

export enum EOtherLanguages {
  TR = 'tr',
  DE = 'de',
  ES = 'es',
  FR = 'fr',
  IT = 'it',
  PL = 'pl',
}

export type TLanguageOptions = {
  [key in ELanguages]?: string;
};

export type TOtherLanguageOptions = {
  [key in EOtherLanguages]: string;
};

export const SIGNED_INT_11_MAX_VALUE = 2147483647;
export const MYSQL_MAX_TEXT_LENGTH = 65535;
export const MYSQL_DEFAULT_VARCHAR_LENGTH = 255;
export const DEFAULT_CHECK_IN_PIN = '1234';
export const DEFAULT_EXCEL_UPLOAD_LIMIT = 500;
export const DEFAULT_EXCEL_UPLOAD_LIMIT_FOR_LOGBOOK_AND_FORM = 300;
export const NAME_COLUMN_MAX_LENGTH = 50;
export const BULK_REQUEST_SPLIT_THRESHOLD = 50;
export const COLOR_HEX_CODE_VARCHAR_LENGTH = 6;

@Injectable({ providedIn: 'root' })
export class DefaultIntl extends OwlDateTimeIntl {
  constructor(private readonly translate: TranslateService) {
    super();
  }

  public cancelBtnLabel: string = this.translate.instant('general.cancel');

  public setBtnLabel: string = this.translate.instant('owlDatePicker.apply');
}

export const localeDateObject = {
  de: 'Deutsch',
  'en-au': 'English (Australia)',
  'en-ca': 'English (Canada)',
  'en-nz': 'English (New Zealand)',
  'en-ie': 'English (Ireland)',
  'en-gb': 'English (United Kingdom)',
  en: 'English (United States)',
  es: 'Español',
  fr: 'Français',
  'fr-ca': 'Français (Canada)',
  it: 'Italiano',
  pl: 'Polski',
  tr: 'Türkçe',
};

export enum ELanguageOptions {
  en = 'English',
  tr = 'Türkçe',
}

export type TSeparator = '.' | ',';

export enum EColors {
  RED = '#DD332B',
  LIGHT_RED = '#FF726B',
  GREEN = '#6FC764',
  ORANGE = '#FF9347',
  YELLOW = '#FFC107',
  GRAY = '#868686',
  BLUE = '#448AFF',
  DARK_GREEN = '#1E970E',
  VIOLET = '#AC80F7',
  TURQUOISE = '#6FCECE',
  PURPLE = '#922D61',
  BROWN = '#98361D',
  LIGHT_GRAY = '#BDBDBD',
}

export const emptyAction = of(new AppActions.EmptyAction());

export enum EResetType {
  ALL = 'all',
  ONLY_IS_ENABLED = 'onlyIsEnabled',
  ONLY_VALUE = 'onlyValue',
  VALUE_AND_IS_ENABLED = 'valueAndIsEnabled',
}

export const advancedFilterFilterCardSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

export const emptyUserMeta: IUserMeta = { pageConfig: {}, filterConfig: {}, favoriteLogbooks: [], changedRights: [] };

export const fragmentedUrls: string[] = ['my-tasks', 'settings/scopes'];

export const parameterizedUrls: string[] = ['reports/form-entries'];

export type formIoTraverseKeys = 'criticalField' | 'isConformative';

export enum ENotificationType {
  EMAIL = 1,
  BROWSER = 2,
  CHAT = 3,
  INVALID = -1,
}

export const isChatUrlValid: RegExp = new RegExp(/^http(s?):\/\/.*?(.?)((office.com)|(google.com)|(slack.com))\/.*?/);

export const systemUserDefaultEmail: string = 'system_user@scw.ai';

export const DEFAULT_EXCEL_COLUMN_TRUNCATE_OPTIONS = { omission: '', length: 300 };

export const noApprovalRequiredDbId = 1 as const;
