import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'format',
})
export class FormatPipe implements PipeTransform {
  transform(value: moment.Moment, format: string): string {
    return value.format(format);
  }
}
