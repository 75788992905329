import { Component, Input } from '@angular/core';

@Component({
  selector: 'scw-mat-modal-section-title',
  templateUrl: './scw-mat-modal-section-title.component.html',
  styleUrls: ['./scw-mat-modal-section-title.component.scss'],
})
export class ScwMatModalSectionTitleComponent {
  @Input() icon!: string;
  @Input() title!: string;
}
