import { Directive, HostListener, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({ selector: '[EmptyStringToNull]' })
export class EmptyStringToNullDirective {
  constructor(@Self() private ngControl: NgControl) {}

  @HostListener('keyup') onKeyUp() {
    if (this.ngControl.value !== undefined && this.ngControl.value !== null && this.ngControl.value.trim() === '') {
      this.ngControl.reset(null);
    }
  }
}
