import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import {
  IOnActionButtonClickEmitter,
  ISideConfigBarOptions,
  ISideConfigBarOptionsTab,
  ITabRow,
} from './side-config-bar.model';
import { animate, style, transition, trigger } from '@angular/animations';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import * as _ from 'lodash';
import { isMobile } from '../../helper/app-helper';

@Component({
  selector: 'side-config-bar',
  templateUrl: './side-config-bar.component.html',
  styleUrls: ['./side-config-bar.component.scss', '../../../../scss/custom.scss'],
  animations: [
    trigger('fadeInOutTranslate', [
      transition(':enter', [style({ opacity: 0 }), animate('400ms ease-in-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ transform: 'translate(0)' }), animate('400ms ease-in-out', style({ opacity: 0 }))]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SideConfigBarComponent implements OnInit, OnChanges {
  @Input() options!: ISideConfigBarOptions;
  @Input() customFilterButton!: TemplateRef<any>;
  @Input() customHeader!: TemplateRef<any>;
  @Input() customTabHeader!: TemplateRef<any>;
  @Input() customTabContent!: TemplateRef<any>;
  @Output() onApplyClick = new EventEmitter<IOnActionButtonClickEmitter>();
  @Output() onSetAsDefaultClick = new EventEmitter<IOnActionButtonClickEmitter>();

  public tabs: ISideConfigBarOptionsTab[] = [];
  public sideBarStatus: boolean = false;
  public isMobile: boolean = false;
  public tabSize!: number;

  toggleSideBar(): void {
    this.sideBarStatus = !this.sideBarStatus;

    if (this.sideBarStatus) {
      this.cloneOptionTabs();
    }
  }

  drop($event: CdkDragDrop<string[]>, data: any): void {
    moveItemInArray(data, $event.previousIndex, $event.currentIndex);
  }

  onItemSwitchChange(isSelected: boolean, tabIndex: number, rowIndex: number): void {
    this.tabs[tabIndex].rows[rowIndex].selected = isSelected;
    SideConfigBarComponent.sortTabItems(this.tabs[tabIndex].rows);
  }

  public static sortTabItems(itemArray: any[]): void {
    itemArray.sort((a, b) => (a.selected === b.selected ? 0 : a.selected ? -1 : 1));
  }

  onApplyButtonClick(_tabIndex: number, tabRows: ITabRow[], tabKey?: string): void {
    if (!tabKey) {
      return;
    }

    this.onApplyClick.emit({
      rows: tabRows,
      key: tabKey,
    });
  }

  onSetAsDefaultButtonClick(_tabIndex: number, tabRows: ITabRow[], tabKey?: string): void {
    if (!tabKey) {
      return;
    }

    this.onSetAsDefaultClick.emit({
      rows: tabRows,
      key: tabKey,
    });
  }

  cloneOptionTabs(): void {
    if (this.options !== undefined) {
      this.tabs = [];
      this.options.tabs.forEach((item) => {
        this.tabs.push(_.clone(item));
      });

      this.tabs.forEach((item) => {
        SideConfigBarComponent.sortTabItems(item.rows);
      });
    }

    this.tabSize = this.customTabContent === undefined ? this.tabs.length : this.tabs.length + 1;

    document.querySelector('body')?.style.setProperty('--navItemWidth', `${this.tabSize}`);
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.cloneOptionTabs();
  }

  ngOnInit(): void {
    this.cloneOptionTabs();

    this.isMobile = isMobile();
  }
}
