import { Pipe, PipeTransform } from '@angular/core';
import { HelperService } from '../service/helper.service';

@Pipe({
  name: 'historyTableTimePipe',
})
export class HistoryTableTimePipe implements PipeTransform {
  constructor(private readonly helperService: HelperService) {}

  transform(timeInput: string | string[]): string {
    return Array.isArray(timeInput)
      ? this.helperService.getFormIoMultipleValues(timeInput)
      : timeInput.length >= 8
      ? timeInput.substring(0, 5)
      : timeInput;
  }
}
