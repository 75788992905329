<div
  class="form-default form-static-label native-date-picker-container"
  [class.native-date-picker-container-is_disabled]="isDisabled"
  *ngIf="helperService.isTouchDevice"
>
  <input
    [type]="type"
    [disabled]="isDisabled"
    name="activityStartDateTime"
    class="form-control date-picker"
    [class.has-value]="selectedTime !== ''"
    [(ngModel)]="selectedTime"
    (change)="onChange(selectedTime, 'picker')"
    required
    #dt="ngModel"
    placeholder="{{ selectedTime }}"
  />
  <div class="fake-date">
    {{ formattedDate }}
  </div>
</div>

<div class="form-default form-static-label" *ngIf="!helperService.isTouchDevice">
  <input
    [max]="now"
    [owlDateTimeTrigger]="dt"
    [owlDateTime]="dt"
    [(ngModel)]="selectedTime"
    (dateTimeChange)="onChange(selectedTime, 'picker')"
    [disabled]="isDisabled"
    style="background-color: #ffffff"
    placeholder="{{ selectedTime }}"
    readonly
    required
    class="form-control date-picker"
    #dateField="ngModel"
  />
  <owl-date-time #dt [pickerMode]="'dialog'" [pickerType]="pickerType" (afterPickerClosed)="onChange()"></owl-date-time>
</div>
