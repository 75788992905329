import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  IAddPageConfiguration,
  IConfigurationFormDropdown,
  IPageConfiguration,
  IPageConfigurationParams,
} from './page-configuration.model';
import { Observable } from 'rxjs';
import { IBaseOneResponse, IGetManyResponse } from '../../shared/model/interface/crud-response-interface.model';
import {
  IClientPageConfiguration,
  IClientPageDeleteConfiguration,
} from '../../view/settings/logs-table-configuration/logs-table-configuration.model';
import { ServiceUtilities } from '../../shared/helper/service-utilities';

@Injectable({
  providedIn: 'root',
})
export class PageConfigurationService {
  private readonly URLS = {
    LOAD_USER_HOME_PAGE_CONFIGURATION: `${this.baseUrl}/configurations/home/`,
    CLIENT_CONFIGURATION: `${this.baseUrl}/configurations/client/`,
    ADD_USER_CONFIGURATION: `${this.baseUrl}/configurations/add-user/`,
    ACTIVE_FORM_VERSIONS: `${this.baseUrl}/form-versions/actives`,
    USER_FORM_SETTINGS_PAGE_CONFIGURATION: `${this.baseUrl}/configurations/forms/`,
    USER_LOGBOOK_SETTINGS_PAGE_CONFIGURATION: `${this.baseUrl}/configurations/logbooks/`,
    USER_ALL_LOGBOOK_VERSION_SETTINGS_PAGE_CONFIGURATION: `${this.baseUrl}/configurations/all-logbook-versions/`,
  };

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public loadUserHomePageConfiguration(
    params: IPageConfigurationParams,
  ): Observable<IBaseOneResponse<IPageConfiguration>> {
    return this.http.get<IBaseOneResponse<IPageConfiguration>>(
      `${this.URLS.LOAD_USER_HOME_PAGE_CONFIGURATION}?user_id=${params.userId}&logbook_ids=[${params.logbookId}]`,
    );
  }

  public addUserHomePageConfiguration(
    request: IAddPageConfiguration,
  ): Observable<IBaseOneResponse<IPageConfiguration>> {
    return this.http.post<IBaseOneResponse<IPageConfiguration>>(this.URLS.ADD_USER_CONFIGURATION, request);
  }

  public updateUserHomePageConfiguration(
    request: IAddPageConfiguration,
  ): Observable<IBaseOneResponse<IPageConfiguration>> {
    return this.http.put<IBaseOneResponse<IPageConfiguration>>(this.URLS.ADD_USER_CONFIGURATION, request);
  }

  public loadClientHomeConfiguration(): Observable<IGetManyResponse<IClientPageConfiguration>> {
    return this.http.get<IGetManyResponse<IClientPageConfiguration>>(`${this.URLS.CLIENT_CONFIGURATION}`);
  }

  public deleteClientHomeConfiguration(
    request: IClientPageDeleteConfiguration,
  ): Observable<IGetManyResponse<IClientPageConfiguration>> {
    return this.http.delete<IGetManyResponse<IClientPageConfiguration>>(this.URLS.CLIENT_CONFIGURATION, {
      body: {
        issuer: request.issuer,
        reason: request.reason,
        payload: request,
      },
    });
  }

  public loadSelectedLogbookForms(
    formIds: number[],
    parentLogbookIds: number[],
  ): Observable<IGetManyResponse<IConfigurationFormDropdown>> {
    const body: Record<string, string | number> = ServiceUtilities.prepareGenericBodyForRequest({
      form_ids: JSON.stringify(formIds),
      parent_logbook_ids: JSON.stringify(parentLogbookIds),
    });

    return this.http.post<IGetManyResponse<IConfigurationFormDropdown>>(
      `${this.URLS.ACTIVE_FORM_VERSIONS}`,
      { ...body },
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public addClientPageConfiguration(
    request: IAddPageConfiguration,
  ): Observable<IGetManyResponse<IClientPageConfiguration>> {
    return this.http.post<IGetManyResponse<IClientPageConfiguration>>(this.URLS.CLIENT_CONFIGURATION, {
      issuer: request.issuer,
      reason: request.reason,
      payload: request.payload,
    });
  }

  public editClientPageConfiguration(
    request: IAddPageConfiguration,
  ): Observable<IGetManyResponse<IClientPageConfiguration>> {
    return this.http.put<IGetManyResponse<IClientPageConfiguration>>(this.URLS.CLIENT_CONFIGURATION, {
      issuer: request.issuer,
      reason: request.reason,
      payload: request.payload,
    });
  }

  public loadUserFormSettingsPageConfiguration(userId: number): Observable<IBaseOneResponse<IPageConfiguration>> {
    return this.http.get<IBaseOneResponse<IPageConfiguration>>(
      `${this.URLS.USER_FORM_SETTINGS_PAGE_CONFIGURATION}?user_id=${userId}`,
    );
  }

  public loadUserLogbookSettingsPageConfiguration(userId: number): Observable<IBaseOneResponse<IPageConfiguration>> {
    return this.http.get<IBaseOneResponse<IPageConfiguration>>(
      `${this.URLS.USER_LOGBOOK_SETTINGS_PAGE_CONFIGURATION}?user_id=${userId}`,
    );
  }

  public loadUserAllLogbookVersionSettingsPageConfiguration(
    userId: number,
  ): Observable<IBaseOneResponse<IPageConfiguration>> {
    return this.http.get<IBaseOneResponse<IPageConfiguration>>(
      `${this.URLS.USER_ALL_LOGBOOK_VERSION_SETTINGS_PAGE_CONFIGURATION}?user_id=${userId}`,
    );
  }

  public addUserFormSettingsPageConfiguration(
    request: IAddPageConfiguration,
    isUpdate: boolean,
  ): Observable<IBaseOneResponse<IPageConfiguration>> {
    if (isUpdate) {
      return this.http.put<IBaseOneResponse<IPageConfiguration>>(
        this.URLS.USER_FORM_SETTINGS_PAGE_CONFIGURATION,
        request,
      );
    }

    return this.http.post<IBaseOneResponse<IPageConfiguration>>(
      this.URLS.USER_FORM_SETTINGS_PAGE_CONFIGURATION,
      request,
    );
  }

  public addUserLogbookSettingsPageConfiguration(
    request: IAddPageConfiguration,
    isUpdate: boolean,
  ): Observable<IBaseOneResponse<IPageConfiguration>> {
    if (isUpdate) {
      return this.http.put<IBaseOneResponse<IPageConfiguration>>(
        this.URLS.USER_LOGBOOK_SETTINGS_PAGE_CONFIGURATION,
        request,
      );
    }

    return this.http.post<IBaseOneResponse<IPageConfiguration>>(
      this.URLS.USER_LOGBOOK_SETTINGS_PAGE_CONFIGURATION,
      request,
    );
  }

  public addUserALLLogbookVersionSettingsPageConfiguration(
    request: IAddPageConfiguration,
    isUpdate: boolean,
  ): Observable<IBaseOneResponse<IPageConfiguration>> {
    if (isUpdate) {
      return this.http.put<IBaseOneResponse<IPageConfiguration>>(
        this.URLS.USER_ALL_LOGBOOK_VERSION_SETTINGS_PAGE_CONFIGURATION,
        request,
      );
    }

    return this.http.post<IBaseOneResponse<IPageConfiguration>>(
      this.URLS.USER_ALL_LOGBOOK_VERSION_SETTINGS_PAGE_CONFIGURATION,
      request,
    );
  }
}
