import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  IFormSubmissionData,
  ILogsFormEntriesData,
} from '../../../../store/my-tasks/logs-form-entries/logs-form-entries.model';
import { IGetManyResponse, IGetOneResponse } from '../../../model/interface/crud-response-interface.model';
import { TranslateService } from '@ngx-translate/core';
import { IFormSubmissionUserAction, ITableHeader } from '../../../../../constants.model';
import * as _ from 'lodash';
import { IIssuer } from '../../../component/issuer/issuer.model';
import { IReview, IReviewResults } from '../../../../store/my-tasks/my-tasks.model';

@Injectable({
  providedIn: 'root',
})
export class LogsFormEntriesService {
  private readonly LOGS_FORM_ENTRIES = {
    BASE_URL: '/form-submissions/',
    REVIEW: 'review/',
    SEND_TO_REVIEW: 'send-to-review/',
    ACTIVATE: 'activate/',
    USER_ACTIONS_FORM_SUBMISSONS: '/user-actions/form-submissions/',
  };

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getReviewData(params: Record<string, string | number>): Observable<IGetManyResponse<ILogsFormEntriesData>> {
    return this.http.post<IGetManyResponse<ILogsFormEntriesData>>(
      `${this.baseUrl}${this.LOGS_FORM_ENTRIES.BASE_URL}${this.LOGS_FORM_ENTRIES.REVIEW}`,
      { ...params },
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public reviewEntries(reviewPayload: IReview, issuer: IIssuer | null): Observable<IGetOneResponse<IReviewResults[]>> {
    return this.http.post<IGetOneResponse<IReviewResults[]>>(
      `${this.baseUrl}${this.LOGS_FORM_ENTRIES.BASE_URL}${this.LOGS_FORM_ENTRIES.REVIEW}`,
      {
        issuer,
        payload: reviewPayload,
      },
    );
  }

  public getLogsFormEntriesHistory(id: number): Observable<IGetManyResponse<IFormSubmissionUserAction>> {
    return this.http.get<IGetManyResponse<IFormSubmissionUserAction>>(
      `${this.baseUrl}${this.LOGS_FORM_ENTRIES.USER_ACTIONS_FORM_SUBMISSONS}${id}`,
    );
  }

  public getLogsFormEntriesRecord(logbookId: number, id: number): Observable<IGetOneResponse<IFormSubmissionData>> {
    return this.http.get<IGetOneResponse<IFormSubmissionData>>(
      `${this.baseUrl}${this.LOGS_FORM_ENTRIES.BASE_URL}${logbookId}/${id}`,
    );
  }

  public static getLogsFormEntriesTableHeaderDefaults(translateService: TranslateService): ITableHeader[] {
    return _.cloneDeep([
      {
        value: 'formSubmissionsId',
        name: translateService.instant('myTasks.table.headers.logId'),
        sortable: true,
        width: '40px',
        selected: true,
        draggable: true,
        disabled: true,
      },
      {
        value: 'logbookId',
        name: translateService.instant('myTasks.table.headers.logbookId'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'logbookName',
        name: translateService.instant('myTasks.table.headers.logbookName'),
        sortable: false,
        selected: true,
        draggable: true,
      },
      {
        value: 'logbookLegacyId',
        name: translateService.instant('myTasks.table.headers.logbookLegacyId'),
        sortable: false,
        selected: true,
        draggable: true,
      },
      {
        value: 'logbookVersionNumber',
        name: translateService.instant('myTasks.table.headers.logbookVersion'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'mobileLogbook',
        name: translateService.instant('myTasks.table.headers.mobileLogbook'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'parentLogbookName',
        name: translateService.instant('myTasks.table.headers.parentLogbookName'),
        sortable: false,
        selected: true,
        draggable: true,
      },
      {
        value: 'formId',
        name: translateService.instant('myTasks.table.headers.formId'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'formName',
        name: translateService.instant('myTasks.table.headers.formName'),
        sortable: false,
        selected: true,
        draggable: true,
      },
      {
        value: 'legacyId',
        name: translateService.instant('myTasks.table.headers.formLegacyId'),
        sortable: false,
        selected: true,
        draggable: true,
      },
      {
        value: 'formVersionNumber',
        name: translateService.instant('myTasks.table.headers.formVersion'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'formActivityType',
        name: translateService.instant('myTasks.table.headers.formActivityType'),
        sortable: false,
        selected: true,
        draggable: true,
      },
      {
        value: 'createdBy',
        name: translateService.instant('myTasks.table.headers.createdBy'),
        sortable: false,
        selected: true,
        draggable: true,
      },
      {
        value: 'createdAt',
        name: translateService.instant('myTasks.table.headers.createdAt'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'submittedBy',
        name: translateService.instant('myTasks.table.headers.submittedBy'),
        sortable: false,
        selected: true,
        draggable: true,
      },
      {
        value: 'submittedAt',
        name: translateService.instant('myTasks.table.headers.submittedAt'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'approvalStepPosition',
        name: translateService.instant('myTasks.table.headers.status'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'dueDate',
        name: translateService.instant('myTasks.table.headers.dueDate'),
        sortable: true,
        selected: true,
        draggable: true,
      },
      {
        value: 'stateDurationFormatted',
        name: translateService.instant('general.datatable.headers.stateDuration'),
        sortable: true,
        draggable: true,
        selected: false,
      },
      {
        value: 'actions',
        name: translateService.instant('myTasks.table.headers.history'),
        sortable: false,
        selected: true,
        draggable: true,
        disabled: true,
      },
    ]);
  }
}
