import { Directive, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AccordionLinkDirective } from './accordionlink.directive';
import { Subscription, filter } from 'rxjs';

@Directive({
  selector: '[appAccordion]',
})
export class AccordionDirective implements OnInit {
  protected navlinks: AccordionLinkDirective[] = [];
  private countState = 1;
  private routerVar!: Subscription;

  closeOtherLinks(openLink: AccordionLinkDirective): void {
    this.countState = this.countState + 1;

    if (
      (openLink.group !== 'sub-super-toggled' ||
        openLink.group !== 'sub-toggled' ||
        openLink.group !== 'main-toggled') &&
      this.countState === 1 &&
      window.innerWidth <= 1024
    ) {
      const toggledElement = <HTMLElement>document.querySelector('#mobile-collapse');
      toggledElement.click();
    }

    this.navlinks.forEach((link: AccordionLinkDirective) => {
      if (openLink.group === 'sub-toggled') {
        if (link !== openLink && (link.group === 'sub-toggled' || openLink.group !== 'sub-toggled')) {
          link.open = false;
        }
      } else if (openLink.group === 'sub-end-toggled') {
        if (link !== openLink && (link.group === 'sub-end-toggled' || openLink.group !== 'sub-end-toggled')) {
          link.open = false;
        }
      } else {
        if (link !== openLink) {
          link.open = false;
        }
      }
    });
  }

  addLink(link: AccordionLinkDirective): void {
    this.navlinks.push(link);
  }

  removeGroup(link: AccordionLinkDirective): void {
    const index = this.navlinks.indexOf(link);
    if (index !== -1) {
      this.navlinks.splice(index, 1);
    }
  }

  getUrl() {
    return this.router.url;
  }

  ngOnInit(): any {
    this.routerVar = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.countState = 0;
      this.navlinks.forEach((link: AccordionLinkDirective) => {
        if (link.group) {
          const routeUrl = this.getUrl();
          const currentUrl = routeUrl.split('/');
          if (currentUrl.indexOf(link.group) > 0) {
            link.open = true;
            this.closeOtherLinks(link);
          }
        }
      });
    });
  }

  constructor(private router: Router) {}
}
