import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import {
  ApiServer,
  ICommonSettings,
  IDefaultSelection,
  DependencyType,
  DropdownFilterConfiguration,
  IDropdownSettings,
} from './dropdown/dropdown.model';
import {
  ApprovalStatus,
  AuditTrailAction,
  AuditTrailLocation,
  CriticalField,
  Form,
  Logbook,
  RuleType,
  Status,
  TypeFilterableObjects,
  User,
} from './filterable-objects.class';
import { IFilterDateRangePicker } from './filter-date-range-picker/filter-date-range-picker.model';
import { Type } from '@angular/core';
import { DropdownComponent } from './dropdown/dropdown.component';
import { FilterDateRangePickerComponent } from './filter-date-range-picker/filter-date-range-picker.component';
import { SearchBoxComponent } from './search-box/search-box.component';
import { IHttpOption } from '../../../store/filter/filter.model';
import { IUserGetOneCRUDData } from '../../service/user/user.model';
import { AdvancedFilterComponent } from './advanced-filter/advanced-filter.component';
import { ButtonGroupComponent } from './button-group/button-group.component';
import { ScwMatButtonGroupButtons } from '../scw-mat-ui/scw-mat-button-group/scw-mat-button-group.model';
import { ISelect } from '../scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { AdvancedFilterObjects } from './advanced-filter/advanced-filter.model';
import { ILogbookVersion } from '../../../store/logbook-versions/logbook-versions.model';
import { ERuleType } from '../../../store/settings/state-rule-and-automation/rules/rule.model';

export type FilterDataObjectTypes =
  | IKeyValueDropdown
  | IUserGetOneCRUDData
  | IUserObject
  | IUsersFilterData
  | IUsersCRUD
  | IFormsCRUD
  | ILogbooksCRUD
  | ISelect<number | string, string>;

export type FilterDataServiceCRUDTypes = IUsersFilterData | IUsersCRUD | IFormsCRUD | ILogbooksCRUD;

export interface IDefaultSelectionValues {
  [key: string]: IDefaultSelection;
}

export interface IFilterClass {
  objectNameProp: string;
  data: FilterDataObjectTypes[];
  dataSubject: Subject<FilterDataObjectTypes[]> | BehaviorSubject<FilterDataObjectTypes[]>;
  apiServer: ApiServer;

  setApiServer(server: ApiServer): void;

  getDataObservable(): Observable<FilterDataObjectTypes[]>;

  getObjectNameProp(): string;

  getInitialData(): FilterDataObjectTypes[];

  getFilterData(options: IHttpOption): void;

  getDataWithInitialData(initialData: FilterDataObjectTypes[]): FilterDataObjectTypes[];
}

export interface IFilterComponent {
  elementID: string;
  outputOptions: IOutputOptions | ISimpleOutputOptions;
  filterListenerConfiguration?: DropdownFilterConfiguration[];
  disabledSubject: BehaviorSubject<boolean>;
  submit?: boolean;
  defaultSettings?: IDropdownSettings | IFilterDateRangePicker | any;
  dropdownObjectClass?: TypeFilterableObjects;
  depends?: DependencyType;
  dropdownSettings?: IDropdownSettings;
  dependedOptionListenerConfiguration?: any[];

  subscribeFilterListener?(config: DropdownFilterConfiguration): void;

  filterData?(data: any): void;

  publishSelectedItems?(): void;

  getFiltersOutputs(): any;

  subscribeDependedOptionListener?(config: any): void;
}

export interface IKeyValueDropdown {
  key: string;
  name?: string;
}

export interface IFilterCardOption {
  rows: IFilterCardOptionRow;
}

export interface IFilterCardOptionRow {
  [index: number]: IRowConfiguration[];
}

export interface IOutputOptions {
  filterObjectId: string;
  filterObjectProp: string;
  returnFilterObjectAllProp?: boolean;
}

export interface ISimpleOutputOptions {
  filterObjectId: string;
}

export interface IDisableComponent {
  elementId: string;
}

export interface IRowConfiguration {
  type: Type<
    | DropdownComponent
    | FilterDateRangePickerComponent
    | SearchBoxComponent
    | AdvancedFilterComponent
    | ButtonGroupComponent
  >;
  object?: Type<
    User | Status | ApprovalStatus | Logbook | Form | CriticalField | AuditTrailAction | AuditTrailLocation | RuleType
  >;
  advancedFilterObject?: Type<AdvancedFilterObjects>;
  cls?: string;
  outputOptions: IOutputOptions | ISimpleOutputOptions;
  options?: IDropdownSettings | ISearchBoxSettings | IFilterDateRangePicker | IButtonGroupSettings;
  elementId?: string;
  depends?: DependencyType;
  disableComponent?: IDisableComponent;
  filter?: IDropdownItemFilter;
  dependedOptions?: any[];
}

export interface IDropdownItemFilter {
  /**
   * preCondition output determinates to execute condition or not.
   *
   * For example: You don't want to filter items if all items has the date field null.
   * You can check this on filter condition, but it will be expensive and unnecessary.
   * This preCondition function solves that.
   *
   * @param subject Usually the main array or main object being processed in the component.
   *
   */
  preCondition?: (subject: any) => boolean;

  /**
   * Condition to be used in Array.prototype.filter()
   *
   * @param element The current element being processed in the array.
   *
   * @param index The index of the current element being processed in the array.
   *
   * @param array The array filter was called upon.
   *
   * */
  condition: (element: unknown, index?: number, array?: unknown[]) => boolean;
}

export interface ISearchBoxSettings extends ICommonSettings {
  maxLength?: number;
  placeHolder?: string;
  cls?: string;
}

export interface IButtonGroupSettings extends ICommonSettings {
  buttons: ScwMatButtonGroupButtons[];
  value?: any;
}

export interface ICreatedChangedCRUD {
  createdBy: number;
  createdAt: Date;
  updatedBy: number;
  updatedAt: Date;
}

export interface IFormsCRUD {
  id: number;
  name: string;
  formId: string;
  versionNumber: string;
  createdBy: number;
  createdAt: Date;
  changedBy: number;
  changedAt: Date;
}

export interface ILogbooksCRUD {
  id: number;
  name: string;
  logbookId: string;
  versionNumber: string;
  createdBy: number;
  createdAt: Date;
  changedBy: number;
  changedAt: Date;
  selectedForms?: number[];
  version?: ILogbookVersion;
}

export class FilterClass implements IFilterClass {
  public objectNameProp: string = 'name';
  public subscribe: Subscription | null = null;
  public data!: FilterDataObjectTypes[];
  public dataSubject: Subject<any> = new Subject<object>();
  public apiServer = ApiServer.Django;

  public setApiServer(server: ApiServer): void {
    this.apiServer = server;
  }

  public getDataObservable(): Observable<FilterDataObjectTypes[]> {
    return this.dataSubject.asObservable();
  }

  public getObjectNameProp(): string {
    return this.objectNameProp;
  }

  public getDataWithInitialData(_initialData: FilterDataObjectTypes[]): FilterDataObjectTypes[] {
    return this.data;
  }

  getInitialData(): FilterDataObjectTypes[] {
    return [];
  }

  getFilterData(_options: IHttpOption): void {
    console.warn('Your getFilterData method not found in your filter class.');
  }
}

export type FilterConditionTypes =
  | '$eq'
  | '$ne'
  | '$gt'
  | '$lt'
  | '$gte'
  | '$lte'
  | '$starts'
  | '$ends'
  | '$cont'
  | '$excl'
  | '$in'
  | '$notin'
  | '$isnull'
  | '$notnull'
  | '$between'
  | '$eqL'
  | '$neL'
  | '$startsL'
  | '$endsL'
  | '$contL'
  | '$exclL'
  | '$inL'
  | '$notinL';

export interface ISourceObject {
  sourceObjectId: number;
  destinationTypeId: number;
  destinationObjectId: number;
}

export interface IUserObject extends ISourceObject {
  username: string;
}

export interface IUsersCRUD {
  id?: number;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  password: string;
  checkInCardId: string;
  checkInsPin: string;
  details: string;
  phone: string;
  isActive: number;
  userLanguage: string;
  timeZone: string;
  locale: string;
  dateFormat: string;
  dateTimeFormat: string;
}

export interface IUsersFilterData {
  id?: number;
  fullName: string;
}

export interface IRuleType {
  id: number;
  name: ERuleType;
  displayName: string;
}

export interface DefaultSelectionInterface {
  key: string;
  values: (string | number)[];
  initialDataToAddIntoDropdown?: FilterDataObjectTypes[];
  overrideData?: boolean;
  initialData?: FilterDataObjectTypes[];
}

export interface DefaultSelectionValuesInterface {
  [key: string]: DefaultSelectionInterface;
}
