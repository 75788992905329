import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { OnDestroyDecorator } from '../../../../shared/decorator/on-destroy-decorator';
import { ITableHeader } from '../../../../../constants.model';
import { IReason, IReasonState } from '../../../../store/reason/reason.model';
import { Subject, Subscription } from 'rxjs';
import { ActionsSubject, Store } from '@ngrx/store';
import * as logbookAppReducer from '../../../../store/logbook.reducer';
import { ScwMatButtonGroupButtons } from '../../../../shared/component/scw-mat-ui/scw-mat-button-group/scw-mat-button-group.model';
import * as _ from 'lodash';
import { IActionReason, IActionReasonPayload, IActionReasonRules } from './action-reasons.model';
import { HelperService } from '../../../../shared/service/helper.service';
import { ScwMatInputComponent } from '../../../../shared/component/scw-mat-ui/scw-mat-input/scw-mat-input.component';
import * as ReasonActions from '../../../../store/reason/reason.actions';
import { IIssuerAndReason } from '../../../../shared/component/before-action-preparer/before-action-preparer.model';
import { IIssuer } from '../../../../shared/component/issuer/issuer.model';
import { ofType } from '@ngrx/effects';

@OnDestroyDecorator
@Component({
  selector: 'app-action-reasons',
  templateUrl: './action-reasons.component.html',
  styleUrls: ['./action-reasons.component.scss'],
})
export class ActionReasonsComponent implements OnInit, OnDestroy {
  @ViewChild('action_reasons_modal') scopeModal!: TemplateRef<unknown>;

  constructor(
    private readonly modal: NgbModal,
    private readonly translate: TranslateService,
    private readonly store: Store<logbookAppReducer.LogbookAppState>,
    private readonly helperService: HelperService,
    private readonly storeActions: ActionsSubject,
  ) {}

  private reasons$!: IReason[];
  private reasonsToCreate: IReason[] = [];
  private reasonsToUpdate: IActionReason[] = [];
  private actionReasonPayload: IActionReasonPayload = {
    reason: null,
    reasons: [],
  };
  private actionReasonsModalRef!: NgbModalRef;
  private reason: string | undefined | null;
  private issuer!: IIssuer | null;
  private readonly subscriptions: Subscription[] = [];

  protected reasonList!: IActionReason[];
  protected emptyReasonRecordCount: number = 0;
  protected reasonRules: IActionReasonRules = {
    name: [
      this.helperService.getIsNotBlankFormRule(),
      this.helperService.getRequiredFormRule(),
      this.helperService.getMaxLengthFormRule(255),
    ],
    additionalFieldLabel: [
      this.helperService.getIsNotBlankFormRule(),
      this.helperService.getRequiredFormRule(),
      this.helperService.getMaxLengthFormRule(255),
    ],
  };
  protected reasonActionsToSubscribe: string[] = [
    ReasonActions.UPSERT_ACTION_REASONS_COMPLETED,
    ReasonActions.FETCH_ERROR,
  ];
  protected issuerActionsToSubscribe: string[] = [
    ReasonActions.UPSERT_ACTION_REASONS_COMPLETED,
    ReasonActions.FETCH_ERROR,
  ];
  protected actionSubject: Subject<boolean> = new Subject<boolean>();
  protected readonly activeText: string = this.translate.instant('general.active');
  protected readonly disabledText: string = this.translate.instant('general.disabled');

  public actionReasonsTableHeaders: ITableHeader[] = [
    {
      value: 'actionReasonText',
      name: this.translate.instant('general.datatable.headers.actionReasonText'),
      sortable: false,
      width: '40%',
    },
    {
      value: 'additionalTextField',
      name: this.translate.instant('general.datatable.headers.additionalTextField'),
      sortable: false,
      width: '40%',
    },
    {
      value: 'isActive',
      name: this.translate.instant('general.datatable.headers.status'),
      sortable: false,
      width: '10%',
    },
  ];
  public statusGroupButtons: ScwMatButtonGroupButtons[] = [
    {
      text: this.activeText,
      value: true,
    },
    {
      text: this.disabledText,
      value: false,
    },
  ];

  public ngOnInit(): void {
    this.subscriptions.push(
      this.store.select('reasonStore').subscribe((state: IReasonState): void => {
        if (state.getReasonsLoaded && !state.getReasonsLoading && state.reasonData) {
          this.reasons$ = _.cloneDeep(state.reasonData);
          this.setReasonList();
        }
      }),

      this.storeActions
        .pipe(ofType(ReasonActions.UPSERT_ACTION_REASONS_COMPLETED))
        .subscribe((response: ReasonActions.UpsertActionReasonsCompleted): void => {
          if (response.payload.success) {
            this.helperService.showToastMessage(
              true,
              this.translate.instant('general.success'),
              this.translate.instant('general.changesSavedSuccessfully'),
            );

            this.actionReasonsModalRef.close();

            this.store.dispatch(new ReasonActions.GetReasons(true));
          }
        }),
      this.storeActions.pipe(ofType(ReasonActions.FETCH_ERROR)).subscribe((): void => {
        this.actionReasonPayload = {
          reason: null,
          reasons: [],
        };
      }),
    );
  }

  public showModal(): void {
    this.setReasonList();
    this.resetPayloadReasons();

    this.actionReasonsModalRef = this.modal.open(this.scopeModal, {
      keyboard: false,
      backdrop: 'static',
      windowClass: 'scw-modal-xl',
    });
  }

  public calculateEmptyReasonRecordCount(): void {
    this.emptyReasonRecordCount = this.reasonList.filter(
      (reason: IReason): boolean =>
        _.isEmpty(reason.name) || (reason.hasAdditionalField && _.isEmpty(reason.additionalFieldLabel)),
    ).length;
  }

  public addEmptyRow(): void {
    if (this.emptyReasonRecordCount < 3) {
      const newEmptyReason: IReason = {
        id: undefined,
        name: undefined,
        hasAdditionalField: false,
        additionalFieldLabel: undefined,
        isActive: true,
      };

      this.reasonList.push(newEmptyReason);
      this.reasonsToCreate.push(newEmptyReason);
    }

    this.calculateEmptyReasonRecordCount();
  }

  public onBaseFieldChange(_$event: unknown, reason: IReason): void {
    this.onFieldChangeOperations(reason);
  }

  public onAdditionalFieldChange(
    $event: boolean,
    reason: IReason,
    additionalFieldLabelComponent: ScwMatInputComponent,
  ): void {
    if (!$event) {
      additionalFieldLabelComponent.clearErrorMessage();
    }

    this.onFieldChangeOperations(reason);
  }

  public onFocusOut(reason: IReason, property: 'name' | 'additionalFieldLabel'): void {
    reason[property] = reason[property]?.trim();
  }

  public onSubmitActionReasons(isValid: boolean): void {
    if (!isValid || this.checkReasonTextDuplicity()) {
      return;
    }

    this.actionSubject.next(true);
  }

  public dispatchAction(issuerAndReason: IIssuerAndReason): void {
    this.issuer = issuerAndReason.issuer;
    this.reason = issuerAndReason.reason;

    if (!this.reason) {
      return;
    }

    this.prepareActionReasonPayload(this.reason);

    this.store.dispatch(new ReasonActions.UpsertActionReasons(this.actionReasonPayload, this.issuer));
  }

  private addReasonToUpdatePayload(reason: IReason): void {
    if (reason.id && !this.reasonsToUpdate.includes(reason)) {
      this.reasonsToUpdate.push(reason);
    }
  }

  private setReasonList(): void {
    this.reasonList = this.reasons$.map((reason: IReason): IActionReason => {
      return {
        ...reason,
        fieldsDisabled: !reason.isActive,
      };
    });

    this.calculateEmptyReasonRecordCount();
  }

  private resetPayloadReasons(): void {
    this.reasonsToCreate = [];
    this.reasonsToUpdate = [];
    this.actionReasonPayload = {
      reason: null,
      reasons: [],
    };
  }

  private prepareActionReasonPayload(reason: string): void {
    this.actionReasonPayload.reasons.push(
      ...this.reasonsToCreate.map((reason: IReason): IReason => {
        return {
          ...reason,
          additionalFieldLabel: reason.hasAdditionalField ? reason.additionalFieldLabel : null,
        };
      }),
    );

    this.actionReasonPayload.reasons.push(
      ...this.reasonsToUpdate.map((reasonToUpdate: IActionReason): IReason => {
        delete reasonToUpdate.fieldsDisabled;

        return {
          ...reasonToUpdate,
          additionalFieldLabel: reasonToUpdate.hasAdditionalField ? reasonToUpdate.additionalFieldLabel : null,
        };
      }),
    );

    this.actionReasonPayload.reason = reason;
  }

  private checkReasonTextDuplicity(): boolean {
    const jointReasonNameList: string[] = this.reasonList.map((reason: IReason): string => {
      return reason.name ?? '';
    });

    const hasDuplicates: boolean = jointReasonNameList.length !== new Set(jointReasonNameList).size;

    if (hasDuplicates) {
      this.helperService.showToastMessage(
        false,
        this.translate.instant('general.failed'),
        this.translate.instant('settings.actionReasons.duplicateReasonTextErrorMessage'),
      );
    }

    return hasDuplicates;
  }

  private onFieldChangeOperations(reason: IReason): void {
    this.calculateEmptyReasonRecordCount();
    this.addReasonToUpdatePayload(reason);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription?.unsubscribe());
  }
}
