import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { getApiUrl } from './utilities';
import * as _ from 'lodash';

interface TranslationResponseInterface {
  data: LanguageValuesResponseInterface[];
  success: boolean;
  date: string;
}

interface LanguageValuesResponseInterface {
  value: string;
  language: string;
  key: string;
}

@Injectable()
export class CustomTranslateLoader implements TranslateLoader {

  private readonly TRANSLATIONS = {
    USER_TRANSLATION_URL: `${getApiUrl()}/get_user_translations/`,
  };

  constructor(private http: HttpClient) {}

  getTranslation(language: string): Observable<any> {

    return this.http.get<TranslationResponseInterface>(this.TRANSLATIONS.USER_TRANSLATION_URL).pipe(
      switchMap((response: TranslationResponseInterface) => {
        const { data, success } = response;
        const translations = {};

        if (success) {

          data.forEach((item:LanguageValuesResponseInterface) => {
            _.set(translations, item['key'], item['value']);
          });

          return of(translations);
        }
        return of({});

      }),
      catchError(() => {
        return of({});
      }),
    );
  }
}
