import { createAction, props } from '@ngrx/store';
import { IBulkResponseRecord, IGetManyResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { IIssuer } from '../../../shared/component/issuer/issuer.model';
import { IIssuerAndReason } from '../../../shared/component/before-action-preparer/before-action-preparer.model';
import { IActivityType, IAddActivityType, IBulkEditActivityType, IValidationResult } from './activity-type.model';
import { IActivityTypeTableQuery } from '../../../view/settings/activity-type/activity-type.model';

export enum ActionTypes {
  ACTIVITY_TYPE_DATA_LOADING = '[ACTIVITY TYPES] ACTIVITY TYPE DATA LOADING',
  ACTIVITY_TYPE_DATA_LOADED = '[ACTIVITY TYPES] ACTIVITY TYPE DATA LOADED',
  ADD_ACTIVITY_TYPE = '[ACTIVITY TYPES]  ADD ACTIVITY TYPE',
  ADD_ACTIVITY_TYPE_COMPLETED = '[ACTIVITY TYPES] ADD ACTIVITY TYPE COMPLETED',
  EDIT_ACTIVITY_TYPE = '[ACTIVITY TYPES] EDIT ACTIVITY TYPE',
  EDIT_ACTIVITY_TYPE_COMPLETED = '[ACTIVITY TYPES] EDIT ACTIVITY TYPE COMPLETED',
  DELETE_ACTIVITY_TYPE = '[ACTIVITY TYPES] DELETE ACTIVITY TYPE',
  DELETE_ACTIVITY_TYPE_COMPLETED = '[ACTIVITY TYPES] DELETE ACTIVITY TYPE COMPLETED',
  BULK_DELETE_ACTIVITY_TYPE = '[ACTIVITY TYPES] BULK DELETE ACTIVITY TYPE',
  BULK_DELETE_ACTIVITY_TYPE_COMPLETED = '[ACTIVITY TYPES] BULK DELETE ACTIVITY TYPE COMPLETED',
  BULK_EDIT_ACTIVITY_TYPE = '[ACTIVITY TYPES] BULK EDIT ACTIVITY TYPE',
  BULK_EDIT_ACTIVITY_TYPE_COMPLETED = '[ACTIVITY TYPES] BULK EDIT ACTIVITY TYPE COMPLETED',
  VALIDATE_ACTIVITY_TYPE = '[ACTIVITY TYPES] ACTIVITY TYPE VALIDATE ACTIVITY TYPE LOADING',
  VALIDATE_ACTIVITY_TYPE_COMPLETED = '[ACTIVITY TYPES] ACTIVITY TYPE VALIDATE ACTIVITY TYPE LOADED',
  FETCH_ERROR = '[ACTIVITY TYPES] FETCH ERROR',
}

export const ActivityTypesDataLoading = createAction(
  ActionTypes.ACTIVITY_TYPE_DATA_LOADING,
  props<{ tableQuery: IActivityTypeTableQuery }>(),
);

export const ActivityTypesDataLoaded = createAction(
  ActionTypes.ACTIVITY_TYPE_DATA_LOADED,
  props<{ payload: IGetManyResponse<IActivityType> }>(),
);

export const AddActivityType = createAction(
  ActionTypes.ADD_ACTIVITY_TYPE,
  props<{ activityType: IAddActivityType; issuer: IIssuer | null }>(),
);

export const AddActivityTypeCompleted = createAction(
  ActionTypes.ADD_ACTIVITY_TYPE_COMPLETED,
  props<{ payload: IAddActivityType }>(),
);

export const EditActivityType = createAction(
  ActionTypes.EDIT_ACTIVITY_TYPE,
  props<{
    activityType: IAddActivityType;
    id: number;
    issuer: IIssuer | null;
  }>(),
);

export const EditActivityTypeCompleted = createAction(
  ActionTypes.EDIT_ACTIVITY_TYPE_COMPLETED,
  props<{ payload: IAddActivityType }>(),
);

export const DeleteActivityType = createAction(
  ActionTypes.DELETE_ACTIVITY_TYPE,
  props<{ id: number; issuerAndReason: IIssuerAndReason }>(),
);

export const DeleteActivityTypeCompleted = createAction(
  ActionTypes.DELETE_ACTIVITY_TYPE_COMPLETED,
  props<{ payload: IActivityType }>(),
);

export const BulkDeleteActivityType = createAction(
  ActionTypes.BULK_DELETE_ACTIVITY_TYPE,
  props<{ activityTypeIds: number[]; issuerAndReason: IIssuerAndReason }>(),
);

export const BulkDeleteActivityTypeCompleted = createAction(
  ActionTypes.BULK_DELETE_ACTIVITY_TYPE_COMPLETED,
  props<{ payload: IBulkResponseRecord<IActivityType>[] }>(),
);

export const BulkEditActivityType = createAction(
  ActionTypes.BULK_EDIT_ACTIVITY_TYPE,
  props<{ activityTypes: IBulkEditActivityType[]; issuerAndReason: IIssuerAndReason }>(),
);

export const BulkEditActivityTypeCompleted = createAction(
  ActionTypes.BULK_EDIT_ACTIVITY_TYPE_COMPLETED,
  props<{ payload: IBulkResponseRecord<IActivityType>[] }>(),
);

export const validateActivityType = createAction(
  ActionTypes.VALIDATE_ACTIVITY_TYPE,
  props<{
    activityTypeIds: number[];
    issuer: IIssuer | null;
  }>(),
);

export const validateActivityTypeCompleted = createAction(
  ActionTypes.VALIDATE_ACTIVITY_TYPE_COMPLETED,
  props<{ payload: IValidationResult[] }>(),
);

export const FetchError = createAction(ActionTypes.FETCH_ERROR, props<{ error: object }>());
