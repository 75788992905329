import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, UntypedFormControl } from '@angular/forms';

@Directive({
  selector: '[customMin][formControlName],[customMin][formControl],[customMin][ngModel]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: CustomMinDirective,
    multi: true,
  }],
})

export class CustomMinDirective implements Validator {
  @Input() customMin!: number;

  validate(input: UntypedFormControl): null | { customMin: true; [key: string]: any } {
    if (input.value === null || input.value === undefined || input.value === '') {
      return null;
    }
    let customValue = input.value;
    if (customValue instanceof String) {
      customValue = input.value.replace(',', '.');
    }
    customValue = +(customValue);
    return (customValue < (+this.customMin)) ? { customMin : true } : null;
  }
}
