import { ActionReducer, createReducer, on } from '@ngrx/store';
import * as RuleTypeFilterActions from './rule-type.actions';
import { IRuleType } from '../../../shared/component/filter/filter.class';

export interface IRuleTypeFilterState {
  isLoading: boolean;
  isLoaded: boolean;
  data: IRuleType[];
}

export const initialState: IRuleTypeFilterState = {
  isLoading: false,
  isLoaded: false,
  data: [],
};

export const ruleTypeFilterReducer: ActionReducer<IRuleTypeFilterState> = createReducer(
  initialState,
  on(RuleTypeFilterActions.getRuleTypeFilterData, (state: IRuleTypeFilterState) => ({
    ...state,
    isLoading: true,
    isLoaded: false,
  })),
  on(RuleTypeFilterActions.getRuleTypeFilterDataCompleted, (state: IRuleTypeFilterState, { payload }) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    data: payload,
  })),
  on(RuleTypeFilterActions.resetRuleTypeFilter, (state: IRuleTypeFilterState) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    data: [],
  })),
);
