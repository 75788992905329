<div class="table-responsive" [class.overflow-visible]="overflowVisible">
  <table class="table table-striped table-bordered full-data-table tiny-table-td" [class.responsive-table]="responsiveTable">
    <caption class="d-none"></caption>
    <thead>
      <tr>
        <th *ngIf="!hideDragHandle" id="header-draggable-handle" scope="col" class="header-cell">
          {{ draggableColumnText }}
        </th>
        <th
          id="{{ 'header-' + i }}"
          scope="col"
          class="header-cell"
          *ngFor="let header of headers; index as i; trackBy: datatableHeaderTrackBy"
          [style.width]="header.width ? header.width : 'auto'"
          [ngClass]="header.tooltip ? 'overflow-visible' : ''"
        >
          <div class="d-flex flex-row justify-content-start">
            <p class="mb-0" *ngIf="header.subTitle">{{ header.subTitle }}</p>
            <span *ngIf="header.icon">
              <em [class]="header.icon"></em>
            </span>
            {{ header.name }}
            <em
              *ngIf="header.tooltip"
              [ngClass]="header.tooltip?.icon || ''"
              [ngbTooltip]="header.tooltip?.text || ''"
              [tooltipClass]="header?.tooltip?.tooltipClass || ''"
              [autoClose]="false"
            ></em>
          </div>
        </th>
      </tr>
    </thead>
    <tbody
      #dropList
      cdkDropList
      [cdkDropListData]="items"
      (cdkDropListDropped)="drop($event)"
      (cdkDropListSorted)="dragSorted($event)"
      cdkDragLockAxis="y"
      cdkDropListOrientation="vertical"
    >
      <tr *ngIf="noDataText && items.length <= 0">
        <td [attr.colspan]="headers.length + 1">
          {{ noDataText }}
        </td>
      </tr>
      <tr
        cdkDrag
        cdkDragLockAxis="y"
        cdkDropListOrientation="vertical"
        [cdkDragStartDelay]="100"
        [dropContainer]="dropList"
        [cdkDragBoundary]="dropList"
        *ngFor="
          let item of items;
          index as i;
          trackBy: genericTrackByItemFunction;
          let lastRow = last;
          let firstRow = first
        "
        [cdkDragDisabled]="hideDragHandle || dragButtonEnabled"
      >
        <td class="px-xl-1" *ngIf="!hideDragHandle">
          <div class="d-flex h-100 drag-button-container align-items-start justify-content-center p-0 m-0">
            <scw-mat-button size="xs" type="standard" [iconButton]="true" [disabled]="dragButtonEnabled" cdkDragHandle>
              <em class="fas fa-arrows-alt-v"></em>
            </scw-mat-button>
          </div>
        </td>
        <ng-container
          [ngTemplateOutlet]="templateRef"
          [ngTemplateOutletContext]="{
            $implicit: {
              item: item,
              headers: headers,
              i: i,
              currentDragIndex: currentDragIndex,
              lastRow: lastRow,
              firstRow: firstRow
            }
          }"
        ></ng-container>
        <div class="cdk-drag-preview ml-3" *cdkDragPreview>
          <span class="badge draggable-row-new-order">New Order: {{ (currentDragIndex || 0) + 1 }}</span>
        </div>
      </tr>
    </tbody>
  </table>
</div>
<ng-template [ngIf]="errorText || hint">
  <mat-hint [class.error]="errorText" [class.hint]="!errorText && hint">
    <em
      class="sub-text-icon fas"
      [class.fa-exclamation-circle]="errorText"
      [class.fa-info-circle]="!errorText && hint"
    ></em>
    <ng-template [ngIf]="errorText">{{ errorText }}</ng-template>
    <ng-template [ngIf]="!errorText && hint">{{ hint }}</ng-template>
  </mat-hint>
</ng-template>
