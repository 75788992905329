<ng-template #action_reasons_modal let-modal>
  <scw-mat-form (scwFormSubmit)="onSubmitActionReasons($event)">
    <div class="modal-header scw-modal-header">
      <h3 class="modal-title scw-header">
        {{ 'settings.actionReasons.header' | translate }}
      </h3>
      <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">
          <em class="fas fa-times"></em>
        </span>
      </button>
    </div>
    <div class="modal-body">
      <div class="right-grp">
        <scw-mat-small-button [disabled]="emptyReasonRecordCount >= 3" (onClick)="addEmptyRow()">
          <em class="fas fa-plus"></em>
          {{ 'settings.actionReasons.addActionReason' | translate }}
        </scw-mat-small-button>
      </div>
      <div class="scw_mat-add_edit_modal-item">
        <datatable
          class="w-100 overflow-visible"
          [items]="reasonList"
          [headers]="actionReasonsTableHeaders"
          [shouldPaginate]="false"
          [clientSide]="true"
          [overflowYHidden]="true"
          [rowsPerPage]="reasonList.length ?? 1000"
          noDataText="{{ 'general.datatable.noData' | translate }}"
        >
          <ng-template let-item let-index="index">
            <td *ngFor="let header of actionReasonsTableHeaders" class="wider-datatable-rows">
              <ng-container *ngIf="header.value === 'actionReasonText'; else else1">
                <scw-mat-input
                  [block]="true"
                  [disabled]="item['fieldsDisabled']"
                  [(inputModel)]="item['name']"
                  [noPadding]="true"
                  (inputModelChange)="onBaseFieldChange($event, item)"
                  (focusout)="onFocusOut(item, 'name')"
                  [rules]="reasonRules.name"
                  size="xs"
                ></scw-mat-input>
              </ng-container>
              <ng-template #else1>
                <ng-container *ngIf="header.value === 'additionalTextField'; else else2">
                  <div class="d-flex additional-field-label">
                    <div class="d-flex justify-content-center align-items-center">
                      <scw-mat-checkbox
                        [id]="'has-additional-field-checkbox-' + index"
                        [disabled]="item['fieldsDisabled']"
                        [(inputModel)]="item['hasAdditionalField']"
                        (inputModelChange)="onAdditionalFieldChange($event, item, additionalFieldLabel)"
                      >
                      </scw-mat-checkbox>
                    </div>
                    <div class="justify-content-center align-items-start h-100 w-100 min-width-100px">
                      <scw-mat-input
                        [block]="true"
                        [disabled]="!item['hasAdditionalField'] || item['fieldsDisabled']"
                        [(inputModel)]="item['additionalFieldLabel']"
                        [noPadding]="true"
                        (inputModelChange)="onBaseFieldChange($event, item)"
                        (focusout)="onFocusOut(item, 'additionalFieldLabel')"
                        [rules]="item['hasAdditionalField'] ? reasonRules.additionalFieldLabel : []"
                        size="xs"
                        #additionalFieldLabel
                      ></scw-mat-input>
                    </div>
                  </div>
                </ng-container>
              </ng-template>
              <ng-template #else2>
                <ng-container *ngIf="header.value === 'isActive'">
                  <div class="d-flex justify-content-center align-items-center">
                    <scw-mat-button-group
                      class="h-100"
                      size="xs"
                      [buttons]="statusGroupButtons"
                      [(inputModel)]="item['isActive']"
                      (inputModelChange)="onBaseFieldChange($event, item)"
                    ></scw-mat-button-group>
                  </div>
                </ng-container>
              </ng-template>
            </td>
          </ng-template>
        </datatable>
      </div>
    </div>
    <div class="modal-footer">
      <div class="buttons-right">
        <scw-mat-button type="standard" (onClick)="modal.close()">
          {{ 'general.cancel' | translate }}
        </scw-mat-button>
        <scw-mat-button [isSubmitButton]="true">
          {{ 'general.saveChanges' | translate }}
        </scw-mat-button>
      </div>
    </div>
  </scw-mat-form>
</ng-template>

<scw-before-action-preparer
  [reasonActionsToSubscribe]="reasonActionsToSubscribe"
  [issuerActionsToSubscribe]="issuerActionsToSubscribe"
  [parentRef]="this"
  [functionToContinue]="dispatchAction"
  [actionSubject]="actionSubject"
></scw-before-action-preparer>
