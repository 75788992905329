import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, UntypedFormControl } from '@angular/forms';

@Directive({
  selector: '[maxLength][formControlName],[maxLength][formControl],[maxLength][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: CustomMaxLengthValidatorDirective,
      multi: true,
    },
  ],
})
export class CustomMaxLengthValidatorDirective implements Validator {
  @Input() maxLength!: number;

  validate(input: UntypedFormControl): { maxLength: boolean; [key: string]: any } | null {
    if (input.value === null || input.value === undefined || input.value === '') {
      return null;
    }
    let customValue = input.value;
    if (customValue instanceof String) {
      customValue = input.value.replace(',', '.');
    }
    return this.maxLength < customValue.toString().length ? { maxLength: true } : null;
  }
}
